// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Packages
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  Error,
  Star
} from '@material-ui/icons'
import { autorun, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import ContentLoader from 'react-content-loader'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import Shiitake from 'shiitake'

const moment = extendMoment(Moment)

const styles = theme => ({
  crossedOut: {
    position: 'relative',
    '&::before': {
      content: '\'\'',
      width: '100%',
      position: 'absolute',
      right: '0',
      top: '50%',
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      transform: 'skewY(-10deg)'
    }
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  placeholderContainer: {
    extend: 'media',
    display: 'flex',
    height: 0,
    paddingTop: '56.25%',
    position: 'relative',
    width: '100%'
  },
  placeholderContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
})

@withStyles(styles, { withTheme: true })
@injectIntl
@inject('applicationStore')
@observer
class ListingCard extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    listing: PropTypes.object.isRequired,
    onDetailsButtonClick: PropTypes.func
  }

  static defaultProps = {
    onDetailsButtonClick: listingId => () => {}
  }

  state = {
    loaded: false,
    loadError: false,
    photoURI: '',
    photoCaption: ''
  }

  @computed get quote () {
    return this.props.applicationStore.quotes[this.props.listing.listingId] || { state: 'new' }
  }

  @computed get agency () {
    return this.props.applicationStore.agencies[this.props.listing.agencyId] || { roundingRule: 0 }
  }

  static getDerivedStateFromProps = ({ listing: { photos } }, prevState) => {
    if (photos && photos.length > 0) {
      return {
        ...prevState,
        photoURI: photos[0].uri,
        photoCaption: photos[0].caption
      }
    }

    return {
      ...prevState,
      photoURI: '',
      photoCaption: ''
    }
  }

  quoteAutorun = autorun(() => {
    if (this.props.applicationStore.quotes[this.props.listing.listingId].state === 'new' &&
      this.props.applicationStore.travelDates.startDate &&
      this.props.applicationStore.travelDates.endDate) {
      const { listingId, agencyId } = this.props.listing
      const startDate = this.props.applicationStore.travelDates.startDate
      const endDate = this.props.applicationStore.travelDates.endDate
      this.props.applicationStore.getQuote({
        listingId,
        agencyId,
        startDate,
        endDate
      })
    }
  })

  handlePhotoLoaded = () => {
    this.setState({
      loaded: true
    })
  }

  handlePhotoLoadError = () => {
    this.setState({
      loadError: true
    })
  }

  hasDiscount = () =>
    this.quote.state === 'done'
      ? this.quote.pricingDetails.listingDiscount < 0
      : this.props.listing.discounts.filter(discount => moment.range(discount.publishedFrom, discount.publishedUntil).contains(moment())).length > 0

  render () {
    return (
      <Card style={{ display: 'flex', flexFlow: 'column', minHeight: '100%', textDecoration: 'none' }} component={Link} to={`/listing/${this.props.listing.listingId}`}>
        <img hidden src={this.state.photoURI} alt={this.state.photoCaption} onLoad={this.handlePhotoLoaded} onError={this.handlePhotoLoadError} />
        {
          this.state.loadError ? (
            <div className={this.props.classes.placeholderContainer}>
              <Error className={this.props.classes.placeholderContent} color='primary' style={{ fontSize: 40 }} />
            </div>
          ) : this.state.loaded ? (
            <CardMedia
              className={this.props.classes.media}
              image={this.state.photoURI}
              title={this.state.photoCaption}
            />
          ) : (
            <div className={this.props.classes.placeholderContainer}>
              <CircularProgress className={this.props.classes.placeholderContent} />
            </div>
          )
        }
        <CardContent style={{ flex: 1 }}>
          <Typography gutterBottom variant='h6'>
            {this.props.listing.ref}
          </Typography>
          <Typography>
            {this.props.listing.officialRanking > 0 && (
              [...Array(this.props.listing.officialRanking).keys()].map(k => <Star key={k} />)
            )}
          </Typography>
          <Typography align='justify'>
            <Shiitake lines={3} throttleRate={200} tagName='span'>
              {this.props.listing.catchphrases[this.props.intl.locale.split(/[-_]/)[0]] || this.props.listing.catchphrases[this.props.intl.defaultLocale]}
            </Shiitake>
          </Typography>
        </CardContent>
        <CardContent>
          {this.quote.state === 'new' && (
            <Typography variant='caption' style={{ display: 'inline-block', marginRight: '0.25rem' }}>
              <FormattedMessage
                defaultMessage='Accommodation from'
                id='ReservationEnLigne.Components.Listing.From'
              />
            </Typography>
          )}
          {this.quote.state === 'pending' ? (
            <ContentLoader
              height={48}
              width={200}
              speed={2}
              backgroundColor={this.props.theme.palette.primary.light}
              foregroundColor={this.props.theme.palette.primary.dark}
              style={{ height: '1.625rem' }}
            >
              <rect x='0' y='0' rx='10' ry='10' width='200' height='48' />
            </ContentLoader>
          ) : (
            <>
              <Typography variant='h6' style={{ display: 'inline-block', marginRight: '0.25rem' }} className={this.hasDiscount() ? this.props.classes.crossedOut : ''}>
                {/* valeur négative ou zéro en erreur */}
                {((this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice))) <= 0) && (
                  <FormattedMessage
                  defaultMessage='NC'
                  id='ReservationEnLigne.Components.Listing.NC'
                  />
                )}
                {/* Pas d arrondi => comme avant */}
                {this.agency.roundingRule === 0 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                  // eslint-disable-next-line react/style-prop-object
                  <FormattedNumber style='currency' currency='EUR' value={this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice))} />
                )}
                {/* Arrondi à la dizaine de centimes sup */}
                {this.agency.roundingRule === 1 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                  // eslint-disable-next-line react/style-prop-object
                  <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice))) * 10)) / 10} />
                )}
                {/* Arrondis à l'euro sup */}
                {this.agency.roundingRule === 2 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                  // eslint-disable-next-line react/style-prop-object
                  <FormattedNumber style='currency' currency='EUR' value={Math.ceil(this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)))} />
                )}
                {/* Arrondis à la dizaine d'euros sup */}
                {this.agency.roundingRule === 3 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                  // eslint-disable-next-line react/style-prop-object
                  <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice))) / 10)) * 10} />
                )}
              </Typography>
              {
                this.hasDiscount() &&
                  <Typography variant='h6' color='secondary' style={{ display: 'inline-block', marginRight: '0.25rem' }}>
                    {/* Pas d arrondi => comme avant */}
                    {this.agency.roundingRule === 0 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                      // eslint-disable-next-line react/style-prop-object
                      <FormattedNumber style='currency' currency='EUR' value={this.quote.totalPrice || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.props.listing.discounts.map(p => 1 - (p.rate / 100)))} />
                    )}
                    {/* Arrondi à la dizaine de centimes sup */}
                    {this.agency.roundingRule === 1 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                      // eslint-disable-next-line react/style-prop-object
                      <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.props.listing.discounts.map(p => 1 - (p.rate / 100)))) * 10)) / 10} />
                    )}
                    {/* Arrondis à l'euro sup */}
                    {this.agency.roundingRule === 2 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                      // eslint-disable-next-line react/style-prop-object
                      <FormattedNumber style='currency' currency='EUR' value={Math.ceil(this.quote.totalPrice || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.props.listing.discounts.map(p => 1 - (p.rate / 100))))} />
                    )}
                    {/* Arrondis à la dizaine d'euros sup */}
                    {this.agency.roundingRule === 3 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                      // eslint-disable-next-line react/style-prop-object
                      <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice || Math.min(...this.props.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.props.listing.discounts.map(p => 1 - (p.rate / 100)))) / 10)) * 10} />
                    )}
                  </Typography>
              }
            </>
          )}
          {this.quote.state === 'new' && (
            <Typography variant='caption' style={{ display: 'inline-block' }}>
              <FormattedMessage
                defaultMessage='per week'
                id='ReservationEnLigne.Components.Listing.PerWeek'
              />
            </Typography>
          )}


        </CardContent>
        <CardContent>
          {this.quote.state === 'done' && this.quote.available && this.quote.pricingDetails.earlyLast > 0 && (
            <Chip
              style={{ backgroundColor: '#dc3545', color: '#ffffff', display: 'grid', cursor: 'pointer' }} label={
                this.quote.pricingDetails.earlyLast === 1 ? (
                  <FormattedMessage
                    defaultMessage='Early Booking'
                    id='ReservationEnLigne.Components.Listing.Early'
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage='Last Minute'
                    id='ReservationEnLigne.Components.Listing.LastMinute'
                  />)
              }
            />
          )}
        </CardContent>
        <CardActions>
          <Button size='small' color='primary'>
            <FormattedMessage
              defaultMessage='Show more'
              id='ReservationEnLigne.Components.ListingCard.ShowMore'
            />
          </Button>
        </CardActions>
      </Card>
    )
  }
}

export default ListingCard
