// React
import React, { Component } from 'react'
import PropTypes from 'prop-types';

// Packages
import { DayPickerRangeController } from '@fmauneko/react-dates'
import { DayPickerPhrases } from '@fmauneko/react-dates/lib/defaultPhrases'
import CalendarInfoPositionShape from '@fmauneko/react-dates/lib/shapes/CalendarInfoPositionShape'
import DayOfWeekShape from '@fmauneko/react-dates/lib/shapes/DayOfWeekShape'
import DisabledShape from '@fmauneko/react-dates/lib/shapes/DisabledShape'
import FocusedInputShape from '@fmauneko/react-dates/lib/shapes/FocusedInputShape'
import ScrollableOrientationShape from '@fmauneko/react-dates/lib/shapes/ScrollableOrientationShape'
import getPhrasePropTypes from '@fmauneko/react-dates/lib/utils/getPhrasePropTypes'
import { forbidExtraProps, mutuallyExclusiveProps, nonNegativeInteger } from 'airbnb-prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { defineMessages, injectIntl } from 'react-intl'

@injectIntl
class TranslatedDayPickerRangeController extends Component {
  static propTypes = forbidExtraProps({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
    onDatesChange: PropTypes.func,
    startDateOffset: PropTypes.func,
    endDateOffset: PropTypes.func,

    focusedInput: FocusedInputShape,
    onFocusChange: PropTypes.func,
    onClose: PropTypes.func,

    keepOpenOnDateSelect: PropTypes.bool,
    minimumNights: PropTypes.number,
    disabled: DisabledShape,
    isOutsideRange: PropTypes.func,
    isDayBlocked: PropTypes.func,
    isDayHighlighted: PropTypes.func,

    // DayPicker props
    renderMonthText: mutuallyExclusiveProps(PropTypes.func, 'renderMonthText', 'renderMonthElement'),
    renderMonthElement: mutuallyExclusiveProps(PropTypes.func, 'renderMonthText', 'renderMonthElement'),
    enableOutsideDays: PropTypes.bool,
    numberOfMonths: PropTypes.number,
    orientation: ScrollableOrientationShape,
    withPortal: PropTypes.bool,
    initialVisibleMonth: PropTypes.func,
    hideKeyboardShortcutsPanel: PropTypes.bool,
    daySize: nonNegativeInteger,
    noBorder: PropTypes.bool,
    verticalBorderSpacing: nonNegativeInteger,
    horizontalMonthPadding: nonNegativeInteger,

    navPrev: PropTypes.node,
    navNext: PropTypes.node,
    noNavButtons: PropTypes.bool,

    onPrevMonthClick: PropTypes.func,
    onNextMonthClick: PropTypes.func,
    onOutsideClick: PropTypes.func,
    renderCalendarDay: PropTypes.func,
    renderDayContents: PropTypes.func,
    renderCalendarInfo: PropTypes.func,
    calendarInfoPosition: CalendarInfoPositionShape,
    firstDayOfWeek: DayOfWeekShape,
    verticalHeight: nonNegativeInteger,
    transitionDuration: nonNegativeInteger,

    // accessibility
    onBlur: PropTypes.func,
    isFocused: PropTypes.bool,
    showKeyboardShortcuts: PropTypes.bool,
    onTab: PropTypes.func,
    onShiftTab: PropTypes.func,

    // i18n
    monthFormat: PropTypes.string,
    weekDayFormat: PropTypes.string,
    phrases: PropTypes.shape(getPhrasePropTypes(DayPickerPhrases)),
    dayAriaLabelFormat: PropTypes.string,

    isRTL: PropTypes.bool,
  })

  messages = defineMessages({
    calendarLabel: {
      id: 'ReactDates.CalendarLabel',
      defaultMessage: 'Calendar'
    },
    jumpToPrevMonth: {
      id: 'ReactDates.JumpToPrevMonth',
      defaultMessage: 'Move backward to switch to the previous month.'
    },
    jumpToNextMonth: {
      id: 'ReactDates.JumpToNextMonth',
      defaultMessage: 'Move forward to switch to the next month.'
    },
    keyboardShortcuts: {
      id: 'ReactDates.KeyboardShortcuts',
      defaultMessage: 'Keyboard Shortcuts'
    },
    showKeyboardShortcutsPanel: {
      id: 'ReactDates.ShowKeyboardShortcutsPanel',
      defaultMessage: 'Open the keyboard shortcuts panel.'
    },
    hideKeyboardShortcutsPanel: {
      id: 'ReactDates.HideKeyboardShortcutsPanel',
      defaultMessage: 'Close the shortcuts panel.'
    },
    openThisPanel: {
      id: 'ReactDates.OpenThisPanel',
      defaultMessage: 'Open this panel.'
    },
    enterKey: {
      id: 'ReactDates.EnterKey',
      defaultMessage: 'Enter key'
    },
    leftArrowRightArrow: {
      id: 'ReactDates.LeftArrowRightArrow',
      defaultMessage: 'Right and left arrow keys'
    },
    upArrowDownArrow: {
      id: 'ReactDates.UpArrowDownArrow',
      defaultMessage: 'up and down arrow keys'
    },
    pageUpPageDown: {
      id: 'ReactDates.PageUpPageDown',
      defaultMessage: 'page up and page down keys'
    },
    homeEnd: {
      id: 'ReactDates.HomeEnd',
      defaultMessage: 'Home and end keys'
    },
    escape: {
      id: 'ReactDates.Escape',
      defaultMessage: 'Escape key'
    },
    questionMark: {
      id: 'ReactDates.QuestionMark',
      defaultMessage: 'Question mark'
    },
    selectFocusedDate: {
      id: 'ReactDates.SelectFocusedDate',
      defaultMessage: 'Select the date in focus.'
    },
    moveFocusByOneDay: {
      id: 'ReactDates.MoveFocusByOneDay',
      defaultMessage: 'Move backward (left) and forward (right) by one day.'
    },
    moveFocusByOneWeek: {
      id: 'ReactDates.MoveFocusByOneWeek',
      defaultMessage: 'Move backward (up) and forward (down) by one week.'
    },
    moveFocusByOneMonth: {
      id: 'ReactDates.MoveFocusByOneMonth',
      defaultMessage: 'Switch months.'
    },
    moveFocustoStartAndEndOfWeek: {
      id: 'ReactDates.MoveFocustoStartAndEndOfWeek',
      defaultMessage: 'Go to the first or last day of a week.'
    },
    returnFocusToInput: {
      id: 'ReactDates.ReturnFocusToInput',
      defaultMessage: 'Return to the date input field.'
    },
    chooseAvailableStartDate: {
      id: 'ReactDates.ChooseAvailableStartDate',
      defaultMessage: 'Choose {date} as your check-in date. It’s available.'
    },
    chooseAvailableEndDate: {
      id: 'ReactDates.ChooseAvailableEndDate',
      defaultMessage: 'Choose {date} as your check-out date. It’s available.'
    },
    chooseAvailableDate: {
      id: 'ReactDates.ChooseAvailableDate',
      defaultMessage: '{date}'
    },
    dateIsUnavailable: {
      id: 'ReactDates.DateIsUnavailable',
      defaultMessage: 'Not available. {date}'
    },
    dateIsSelected: {
      id: 'ReactDates.DateIsSelected',
      defaultMessage: 'Selected. {date}'
    }
  })

  dayPickerRangeControllerPhrases = {
    calendarLabel: this.props.intl.formatMessage(this.messages.calendarLabel),
    jumpToPrevMonth: this.props.intl.formatMessage(this.messages.jumpToPrevMonth),
    jumpToNextMonth: this.props.intl.formatMessage(this.messages.jumpToNextMonth),
    keyboardShortcuts: this.props.intl.formatMessage(this.messages.keyboardShortcuts),
    showKeyboardShortcutsPanel: this.props.intl.formatMessage(this.messages.showKeyboardShortcutsPanel),
    hideKeyboardShortcutsPanel: this.props.intl.formatMessage(this.messages.hideKeyboardShortcutsPanel),
    openThisPanel: this.props.intl.formatMessage(this.messages.openThisPanel),
    enterKey: this.props.intl.formatMessage(this.messages.enterKey),
    leftArrowRightArrow: this.props.intl.formatMessage(this.messages.leftArrowRightArrow),
    upArrowDownArrow: this.props.intl.formatMessage(this.messages.upArrowDownArrow),
    pageUpPageDown: this.props.intl.formatMessage(this.messages.pageUpPageDown),
    homeEnd: this.props.intl.formatMessage(this.messages.homeEnd),
    escape: this.props.intl.formatMessage(this.messages.escape),
    questionMark: this.props.intl.formatMessage(this.messages.questionMark),
    selectFocusedDate: this.props.intl.formatMessage(this.messages.selectFocusedDate),
    moveFocusByOneDay: this.props.intl.formatMessage(this.messages.moveFocusByOneDay),
    moveFocusByOneWeek: this.props.intl.formatMessage(this.messages.moveFocusByOneWeek),
    moveFocusByOneMonth: this.props.intl.formatMessage(this.messages.moveFocusByOneMonth),
    moveFocustoStartAndEndOfWeek: this.props.intl.formatMessage(this.messages.moveFocustoStartAndEndOfWeek),
    returnFocusToInput: this.props.intl.formatMessage(this.messages.returnFocusToInput),
    chooseAvailableStartDate: ({ date }) => this.props.intl.formatMessage(this.messages.chooseAvailableStartDate, { date }),
    chooseAvailableEndDate: ({ date }) => this.props.intl.formatMessage(this.messages.chooseAvailableEndDate, { date }),
    chooseAvailableDate: ({ date }) => this.props.intl.formatMessage(this.messages.chooseAvailableDate, { date }),
    dateIsUnavailable: ({ date }) => this.props.intl.formatMessage(this.messages.dateIsUnavailable, { date }),
    dateIsSelected: ({ date }) => this.props.intl.formatMessage(this.messages.dateIsSelected, { date })
  }

  render () {
    const { intl, ...dayPickerRangeControllerProps } = this.props
    return (
      <DayPickerRangeController {...dayPickerRangeControllerProps} phrases={this.dayPickerRangeControllerPhrases} keepOpenOnDateSelect />
    )
  }
}

export default TranslatedDayPickerRangeController
