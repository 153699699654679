// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Packages
import {
  Button,
  Card,
  CardContent,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Switch,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import withWidth, {
  isWidthDown
} from '@material-ui/core/withWidth'
import {
  ArrowForward,
  ExpandMore,
  Clear,
  Close,
  Delete,
  Done
} from '@material-ui/icons'
import clsx from 'clsx'
import { when } from 'mobx'
import { observer, inject } from 'mobx-react'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { START_DATE, END_DATE } from '@fmauneko/react-dates/constants'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'

// Internal
import ListingGrid from './ListingGrid'
import Slider from './Slider'
import Spinner from './Spinner'
import TranslatedDayPickerRangeController from './TranslatedDayPickerRangeController'

const moment = extendMoment(Moment)

const styles = theme => ({
  dateArrow: {
    bottom: 0,
    height: '100%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)'
    }
  },
  datePicker: {
    '& *': {
      boxSizing: 'border-box'
    }
  },
  datePickerPaper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0.5%',
    textAlign: 'center',
    '@media (min-height: 435px)': {
      width: 'min-content'
    },
    '@media (max-height: 435px)': {
      display: 'flex',
      paddingRight: '5%'
    },
    '&>*': {
      marginBottom: '1%'
    },
    '&>div': {
      '@media (max-height: 435px)': {
        width: 'min-content',
        margin: 'auto 1%',
        height: 'min-content'
      }
    },
    '&>div>button': {
      marginLeft: '0.5%',
      marginRight: '0.5%',
      marginBottom: '1%',
      '@media (max-height: 435px)': {
        marginBottom: '5%'
      },
      '@media (max-width : 635px)': {
        width: window.screen.availHeight > 435 ? '80%' : 'auto'
      }
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  guestCount: {
    margin: theme.spacing(1),
    width: '20rem'
  },
  header: {
    backgroundColor: '#eeeeee',
    backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'6\' height=\'6\' viewBox=\'0 0 6 6\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23999999\' fill-opacity=\'0.4\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M5 0h1L0 6V5zM6 5v1H5z\'/%3E%3C/g%3E%3C/svg%3E")',
    height: '50vh',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '900px'
    }
  },
  listingBlock: {
    paddingTop: '2rem'
  },
  listingBlockWithButton: {
    paddingTop: '1.5rem'
  },
  slider: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(5)
    }
  }
})

@withWidth()
@withStyles(styles, { withTheme: true })
@injectIntl
@inject('applicationStore')
@observer
class HomePage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  }

  state = {
    focusedInput: null,
    guestCountAnchorEl: null,
    pickerAnchorEl: null,
    searchBarExpanded: false
  }

  messages = defineMessages({
    guestCount: {
      id: 'ReservationEnLigne.Common.GuestCount',
      defaultMessage: '{adults, plural, =0 {} one {# adult} other {# adults}}|{children, plural, =0 {} one {# child} other {# children}}|{babies, plural, =0 {} one {# baby} other {# babies}}'
    }
  })

  guestCountAnchorEl = React.createRef()
  pickerAnchorEl = React.createRef()

  listingFilter = listing => (
    this.props.applicationStore.listingFilters.destination === '' ||
    this.props.applicationStore.listingFilters.destination === 'any' ||
    this.props.applicationStore.listingFilters.destination === listing.city
  ) && (
    this.props.applicationStore.listingFilters.listingType === '' ||
    this.props.applicationStore.listingFilters.listingType === 'any' ||
    this.props.applicationStore.listingFilters.listingType === listing.type
  ) && (
    this.props.applicationStore.listingFilters.petsAccepted
      ? listing.petsAccepted
      : true
  ) && (
    this.props.applicationStore.listingFilters.wifiIncluded
      ? listing.wifiIncluded
      : true
  ) && (
    listing.surfaceArea >= this.props.applicationStore.listingFilters.surfaceAreaRange[0] &&
    listing.surfaceArea <= this.props.applicationStore.listingFilters.surfaceAreaRange[1]
  ) && (
    // Gestion du stop vente => on prend la première ligne de la saison
    this.props.applicationStore.listings.response[listing.listingId].prices.length > 0 &&
    this.props.applicationStore.travelDates.startDate
      ? this.props.applicationStore.travelDates.startDate.isAfter(moment().add(((this.props.applicationStore.listings.response[listing.listingId].prices[0].sellStop) / 24), 'days'))
      : true
  ) && (
    this.props.applicationStore.travelDates.startDate &&
    this.props.applicationStore.travelDates.endDate
      ? this.props.applicationStore.quotes[listing.listingId].state === 'new' ||
        this.props.applicationStore.quotes[listing.listingId].state === 'pending' ||
        (
          this.props.applicationStore.quotes[listing.listingId].state === 'done' &&
          this.props.applicationStore.quotes[listing.listingId].available === true &&
          this.props.applicationStore.quotes[listing.listingId].totalPrice >= this.props.applicationStore.listingFilters.priceRange[0] &&
          this.props.applicationStore.quotes[listing.listingId].totalPrice <= this.props.applicationStore.listingFilters.priceRange[1]
        )
      : (
        Math.min(...listing.prices.map(p => p.weeklyPrice)) >= this.props.applicationStore.listingFilters.priceRange[0] &&
        Math.min(...listing.prices.map(p => p.weeklyPrice)) <= this.props.applicationStore.listingFilters.priceRange[1]
      )
  ) &&
  listing.capacity >= this.props.applicationStore.guestCount.adults + this.props.applicationStore.guestCount.children

  handleDestinationChange = evt => {
    this.props.applicationStore.enableListingFilters()
    this.props.applicationStore.setDestination(evt.target.value)
  }

  handleDateChange = (dates) => {
    this.props.applicationStore.handleTravelDatesChange(dates)
    this.props.applicationStore.enableListingFilters()
  }

  handleExpandClick = () => this.setState(state => ({
    searchBarExpanded: !state.searchBarExpanded
  }))

  handleGuestCountClose = () => this.setState({
    guestCountAnchorEl: null
  })

  handleGuestCountOpen = () => this.setState({
    guestCountAnchorEl: this.guestCountAnchorEl.current
  })

  createGuestCountUpdateHandler = counter => step => () => {
    this.props.applicationStore.enableListingFilters()
    this.props.applicationStore.updateGuestCount(counter, step)
  }

  handleListingTypeChange = evt => {
    this.props.applicationStore.enableListingFilters()
    this.props.applicationStore.setListingType(evt.target.value)
  }

  handlePickerClose = () => this.setState({
    pickerAnchorEl: null
  })

  handlePickerOpen = focusedInput => () => this.setState({
    pickerAnchorEl: this.pickerAnchorEl.current,
    focusedInput
  })

  handlePickerReset = () => this.setState({
    focusedInput: START_DATE
  }) || this.props.applicationStore.resetTravelDates()

  handlePickerClear = () => {
    this.handlePickerReset()
    this.handlePickerClose()
  }

  handlePriceRangeChange = values => {
    this.props.applicationStore.enableListingFilters()
    this.props.applicationStore.setPriceRange(values)
  }

  handleSearchCloseClicked = this.props.applicationStore.disableListingFilters

  handleSurfaceAreaRangeChange = values => {
    this.props.applicationStore.enableListingFilters()
    this.props.applicationStore.setSurfaceAreaRange(values)
  }

  parseGuestCount = guestCount =>
    this.props.intl.formatMessage(this.messages.guestCount, guestCount).split('|').filter(n => n).join(', ')

  componentDidMount () {
    when(
      () => this.props.applicationStore.listings.state === 'done',
      () => {
        const noFavorites = Object.values(this.props.applicationStore.listings.response).filter(listing => listing.indPref === 99).length === 0
        const noDiscounts = Object.values(this.props.applicationStore.listings.response).filter(listing => listing.discounts.filter(discount => moment.range(discount.publishedFrom, discount.publishedUntil).contains(moment())).length > 0).length === 0
        if (noFavorites && noDiscounts) {
          if (this.props.applicationStore.destinations.length > 1) {
            this.handleDestinationChange('any')
          } else {
            this.handleDestinationChange(this.props.applicationStore.destinations[0])
          }
        }
      }
    )
  }

  render () {
    const store = this.props.applicationStore

    let destinations = null
    let listingTypes = null

    if (store.destinations === null) {
      destinations = (<p>Error :(</p>)
    } else if (store.destinations === []) {
      destinations = (<p>Loading</p>)
    } else {
      destinations = (
        <TextField
          id='destination'
          select
          label={
            <FormattedMessage
              defaultMessage='Destination'
              id='ReservationEnLigne.Components.Home.Destination'
            />
          }
          value={store.listingFilters.destination}
          onChange={this.handleDestinationChange}
          helperText={
            <FormattedMessage
              defaultMessage='Where do you want to go ?'
              id='ReservationEnLigne.Components.Home.DestinationHelper'
            />
          }
          fullWidth
        >
          {
            store.destinations.length > 1 && (
              <MenuItem value='any'>
                <em>
                  <FormattedMessage
                    defaultMessage='Anywhere'
                    id='ReservationEnLigne.Components.Home.Anywhere'
                  />
                </em>
              </MenuItem>
            )
          }
          {
            store.destinations.map(destination => (
              <MenuItem key={destination} value={destination}>{destination}</MenuItem>
            ))
          }
        </TextField>
      )
    }

    if (store.listingTypes === null) {
      listingTypes = (<p>Error :(</p>)
    } else if (store.listingTypes === []) {
      listingTypes = (<p>Loading</p>)
    } else {
      listingTypes = (
        <TextField
          id='listingType'
          select
          label={
            <FormattedMessage
              defaultMessage='Listing type'
              id='ReservationEnLigne.Components.Home.ListingType'
            />
          }
          value={store.listingFilters.listingType}
          onChange={this.handleListingTypeChange}
          helperText={
            <FormattedMessage
              defaultMessage='What kind of rental are you looking for?'
              id='ReservationEnLigne.Components.Home.ListingTypeHelper'
            />
          }
          fullWidth
        >
          {
            store.listingTypes.length > 1 && (
              <MenuItem value='any'>
                <em>
                  <FormattedMessage
                    defaultMessage='Any kind'
                    id='ReservationEnLigne.Components.Home.AnyKind'
                  />
                </em>
              </MenuItem>
            )
          }
          {
            store.listingTypes.map(listingType => (
              <MenuItem key={listingType} value={listingType}>{listingType}</MenuItem>
            ))
          }
        </TextField>
      )
    }

    let months = 1
    if (window.innerHeight > 435) {
      months = window.innerWidth < 635 ? 1
        : window.innerWidth < 935 ? 2
          : window.innerWidth < 1235 ? 3
            : window.innerWidth < 1535 ? 4
              : window.innerWidth < 1835 ? 5 : 6
    } else {
      months = window.innerWidth < 850 ? 1
        : window.innerWidth < 1150 ? 2
          : window.innerWidth < 1450 ? 3
            : window.innerWidth < 1750 ? 4
              : window.innerWidth < 2050 ? 5 : 6
    }

    return (
      <div>
        <Paper elevation={2} className={this.props.classes.header}>
          <img src={store.mainAgencyBackdrop} alt='Header backdrop' style={{ height: '100%', width: '100%', objectFit: 'cover', visibility: store.mainAgencyBackdrop ? 'visible' : 'hidden' }} />
          <Grid container justify='center' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Grid item xs={12} md={9} xl={8}>
              <Card elevation={4}>
                <CardContent>
                  <Grid container alignItems='center' justify='center' spacing={3}>
                    <Grid item md={2} xs={12}>
                      {destinations}
                    </Grid>
                    <Grid item md='auto' xs={12}>
                      <div ref={this.guestCountAnchorEl}>
                        <TextField
                          readOnly
                          fullWidth={isWidthDown('sm', this.props.width)}
                          id='guestCount'
                          label={
                            <FormattedMessage
                              defaultMessage='Guests'
                              id='ReservationEnLigne.Components.Home.Guests'
                            />
                          }
                          type='text'
                          value={this.parseGuestCount(this.props.applicationStore.guestCount)}
                          helperText={
                            <FormattedMessage
                              defaultMessage='How many beds will you need ?'
                              id='ReservationEnLigne.Components.Home.GuestsHelper'
                            />
                          }
                          onFocus={this.handleGuestCountOpen}
                        />
                      </div>
                      <Popover disableRestoreFocus open={Boolean(this.state.guestCountAnchorEl)} anchorEl={this.state.guestCountAnchorEl} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} onClose={this.handleGuestCountClose}>
                        <Grid container wrap='nowrap' direction='column' alignItems='stretch' className={this.props.classes.guestCount} spacing={3}>
                          <Grid item xs>
                            <Spinner value={this.props.applicationStore.guestCount.adults} onUpdate={this.createGuestCountUpdateHandler('adults')} minVal={1}>
                              <Typography variant='subtitle1' color='inherit'>
                                Adultes
                              </Typography>
                              <Typography variant='caption' color='inherit'>
                                + de 18 ans
                              </Typography>
                            </Spinner>
                          </Grid>
                          <Grid item xs>
                            <Spinner value={this.props.applicationStore.guestCount.children} onUpdate={this.createGuestCountUpdateHandler('children')}>
                              <Typography variant='subtitle1' color='inherit'>
                                Enfants
                              </Typography>
                              <Typography variant='caption' color='inherit'>
                                - de 18 ans
                              </Typography>
                            </Spinner>
                          </Grid>
                          <Grid item xs>
                            <Spinner value={this.props.applicationStore.guestCount.babies} onUpdate={this.createGuestCountUpdateHandler('babies')}>
                              <Typography variant='subtitle1' color='inherit'>
                                Bébés
                              </Typography>
                              <Typography variant='caption' color='inherit'>
                                Besoin de lit bébé
                              </Typography>
                            </Spinner>
                          </Grid>
                        </Grid>
                      </Popover>
                    </Grid>
                    <Grid item md='auto' xs={12}>
                      <div ref={this.pickerAnchorEl}>
                        <TextField
                          readOnly
                          fullWidth={isWidthDown('sm', this.props.width)}
                          id='startDate'
                          label={
                            <FormattedMessage
                              defaultMessage='From'
                              id='ReservationEnLigne.Components.Home.From'
                            />
                          }
                          type='text'
                          value={this.props.applicationStore.travelDates.startDate ? this.props.applicationStore.travelDates.startDate.format('LL') : ''}
                          helperText={
                            <FormattedMessage
                              defaultMessage='Arrival after {time}'
                              id='ReservationEnLigne.Components.Home.FromHelper'
                              values={{
                                time: moment(this.props.applicationStore.agencies[this.props.applicationStore.mainAgencyId]?.minArrivalTime || '16:00:00', 'HH:mm:ss').format('LT')
                              }}
                            />
                          }
                          onFocus={this.handlePickerOpen(START_DATE)}
                        />
                      </div>
                    </Grid>
                    <Grid item>
                      <ArrowForward color='primary' className={this.props.classes.dateArrow} />
                      <Modal
                        disableRestoreFocus open={Boolean(this.state.pickerAnchorEl) && this.state.focusedInput !== null}
                        onClose={this.handlePickerClose}
                      >
                        <Paper className={this.props.classes.datePickerPaper}>
                          <div>
                            <TranslatedDayPickerRangeController
                              numberOfMonths={months}
                              startDate={this.props.applicationStore.travelDates.startDate}
                              endDate={this.props.applicationStore.travelDates.endDate}
                              focusedInput={this.state.focusedInput}
                              isOutsideRange={this.checkPickerOutsideRange}
                              onDatesChange={this.handleDateChange}
                              onFocusChange={focusedInput => this.setState({ focusedInput })}
                            />
                          </div>
                          <div>
                            <Button variant='contained' color='primary' onClick={this.handlePickerClose}>
                              <Done />&nbsp;
                              <FormattedMessage
                                defaultMessage='Confirm selection'
                                id='ReservationEnLigne.Components.DatePicker.Confirm'
                              />
                            </Button>
                            <Button variant='contained' color='secondary' onClick={this.handlePickerReset}>
                              <Delete />&nbsp;
                              <FormattedMessage
                                defaultMessage='Clear selection'
                                id='ReservationEnLigne.Components.DatePicker.Clear'
                              />
                            </Button>
                            <Button variant='contained' color='secondary' onClick={this.handlePickerClear}>
                              <Clear />&nbsp;
                              <FormattedMessage
                                defaultMessage='Clear and close'
                                id='ReservationEnLigne.Components.DatePicker.Close'
                              />
                            </Button>
                          </div>
                        </Paper>
                      </Modal>
                    </Grid>
                    <Grid item md='auto' xs={12}>
                      <TextField
                        readOnly
                        fullWidth={isWidthDown('sm', this.props.width)}
                        id='endDate'
                        label={
                          <FormattedMessage
                            defaultMessage='To'
                            id='ReservationEnLigne.Components.Home.To'
                          />
                        }
                        type='text'
                        value={this.props.applicationStore.travelDates.endDate ? this.props.applicationStore.travelDates.endDate.format('LL') : ''}
                        helperText={
                          <FormattedMessage
                            defaultMessage='Departure before {time}'
                            id='ReservationEnLigne.Components.Home.ToHelper'
                            values={{
                              time: moment(this.props.applicationStore.agencies[this.props.applicationStore.mainAgencyId]?.maxDepartureTime || '10:00:00', 'HH:mm:ss').format('LT')
                            }}
                          />
                        }
                        onFocus={this.handlePickerOpen(END_DATE)}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        className={clsx(this.props.classes.expand, {
                          [this.props.classes.expandOpen]: this.state.searchBarExpanded
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.searchBarExpanded}
                        aria-label='Expand'
                      >
                        <ExpandMore color='primary' />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
                <Collapse in={this.state.searchBarExpanded} timeout='auto' unmountOnExit>
                  <CardContent>
                    <Grid container alignItems='center' justify='center' spacing={3}>
                      <Grid item md={2} xs={12}>
                        {listingTypes}
                      </Grid>
                      <Grid item md={2} xs={12} className={this.props.classes.slider}>
                        <Slider
                          domain={this.props.applicationStore.priceRangeDomain}
                          label={
                            <FormattedMessage
                              defaultMessage='Price'
                              id='ReservationEnLigne.Components.Home.Price'
                            />
                          }
                          onChange={this.handlePriceRangeChange}
                          values={this.props.applicationStore.listingFilters.priceRange}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} className={this.props.classes.slider}>
                        <Slider
                          domain={this.props.applicationStore.surfaceAreaRangeDomain}
                          label={
                            <FormattedMessage
                              defaultMessage='Surface area'
                              id='ReservationEnLigne.Components.Home.SurfaceArea'
                            />
                          }
                          onChange={this.handleSurfaceAreaRangeChange}
                          values={this.props.applicationStore.listingFilters.surfaceAreaRange}
                        />
                      </Grid>
                      <Grid item md='auto' xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.props.applicationStore.listingFilters.petsAccepted}
                              onChange={this.props.applicationStore.setBooleanFilter('petsAccepted')}
                              color='primary'
                            />
                          }
                          label={
                            <FormattedMessage
                              defaultMessage='Pets accepted'
                              id='ReservationEnLigne.Components.Home.PetsAccepted'
                            />
                          }
                        />
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.props.applicationStore.listingFilters.wifiIncluded}
                              onChange={this.props.applicationStore.setBooleanFilter('wifiIncluded')}
                              color='primary'
                            />
                          }
                          label={
                            <FormattedMessage
                              defaultMessage='Wi-Fi included'
                              id='ReservationEnLigne.Components.Home.WifiIncluded'
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          </Grid>
        </Paper>
        {store.listingFilters.enabled ? (
          <Grid container justify='center' className={this.props.classes.listingBlockWithButton}>
            <Grid item xs={1}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '0.5rem' }}>
                <IconButton color='primary' aria-label='Close Search Results' onClick={this.handleSearchCloseClicked}>
                  <Close />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='h5' style={{ marginTop: '0.5rem' }}>
                <FormattedMessage
                  defaultMessage='Results of your search'
                  id='ReservationEnLigne.Components.Home.SearchResults'
                />
              </Typography>
              <ListingGrid filter={this.listingFilter} />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        ) : (
          <>
            <Grid container justify='center' className={this.props.classes.listingBlock}>
              <Grid item xs={10}>
                <Typography variant='h5'>
                  <FormattedMessage
                    defaultMessage='Our favorites'
                    id='ReservationEnLigne.Components.Home.Favorites'
                  />
                </Typography>
                <ListingGrid filter={listing => listing.indPref === 99} />
              </Grid>
            </Grid>
            <Grid container justify='center' className={this.props.classes.listingBlock}>
              <Grid item xs={10}>
                <Typography variant='h5'>
                  <FormattedMessage
                    defaultMessage='Our current special offers'
                    id='ReservationEnLigne.Components.Home.SpecialOffers'
                  />
                </Typography>
                <ListingGrid filter={listing => listing.discounts.filter(discount => moment.range(discount.publishedFrom, discount.publishedUntil).contains(moment())).length > 0} />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    )
  }
}

export default HomePage
