// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Packages
import L from 'leaflet'
import {
  Avatar,
  // Badge,
  Button,
  Chip,
  Divider,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import withWidth, {
  isWidthDown,
  isWidthUp
} from '@material-ui/core/withWidth'
import {
  ArrowForward,
  Clear,
  Delete,
  Done,
  KeyboardArrowDown,
  KeyboardArrowUp,
  People,
  Place,
  PhotoLibrary,
  Star
} from '@material-ui/icons'
// import { FullscreenControl } from 'mapbox-gl'
import {
  Door,
  MoveResize,
  Shower,
  ShowerHead
} from 'mdi-material-ui'
import mdi from '@mdi/font/css/materialdesignicons.css'
import { autorun, computed, when } from 'mobx'
import { inject, observer } from 'mobx-react'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import {
  Card,
  Carousel
} from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { START_DATE, END_DATE } from '@fmauneko/react-dates/constants'
import Lightbox from 'react-image-lightbox'
import { FormattedMessage, FormattedNumber, defineMessages, injectIntl } from 'react-intl'
import { Map, Marker, TileLayer } from 'react-leaflet'
// import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import { Link } from 'react-router-dom'
/* import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  TumblrShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  TumblrIcon,
  EmailIcon,
  FacebookShareCount,
  TumblrShareCount
} from 'react-share' */

// Internal
import Spinner from './Spinner'
import TranslatedDayPickerRangeController from './TranslatedDayPickerRangeController'

// Assets
import 'bootstrap/dist/css/bootstrap.css'
import 'leaflet/dist/leaflet.css'
import 'react-image-lightbox/style.css'
import {
  agencyMarker,
  listingMarker
} from '../assets/MapMarkers'

// const Map = ReactMapboxGl({
//   accessToken: 'pk.eyJ1IjoiaGVsaW9zY29ubmVjdCIsImEiOiJjam41dGhnaHMybDcxM3dwbDh2ZmtjdnFoIn0.kmmTD7RT2YBHj0Kt2PDSUg'
// });

const moment = extendMoment(Moment)

const styles = theme => ({
  ...mdi,
  chip: {
    margin: theme.spacing(1 / 2),
    '&:first-child': {
      marginLeft: 0
    }
  },
  chipArray: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3)
  },
  crossedOut: {
    position: 'relative',
    '&::before': {
      content: '\'\'',
      width: '100%',
      position: 'absolute',
      right: '0',
      top: '50%',
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
      transform: 'skewY(-10deg)'
    }
  },
  datePickerPaper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '0.5%',
    textAlign: 'center',
    '@media (min-height: 435px)': {
      width: 'min-content'
    },
    '@media (max-height: 435px)': {
      display: 'flex',
      paddingRight: '5%'
    },
    '&>*': {
      marginBottom: '1%'
    },
    '&>div': {
      '@media (max-height: 435px)': {
        width: 'min-content',
        margin: 'auto 1%',
        height: 'min-content'
      }
    },
    '&>div>button': {
      marginLeft: '0.5%',
      marginRight: '0.5%',
      marginBottom: '1%',
      '@media (max-height: 435px)': {
        marginBottom: '5%'
      },
      '@media (max-width : 635px)': {
        width: window.screen.availHeight > 435 ? '80%' : 'auto'
      }
    }
  },
  guestCount: {
    margin: theme.spacing(1),
    width: '20rem'
  },
  lightboxButton: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: '1em',
    right: '15%',
    zIndex: '1'
  },
  lightboxButtonIcon: {
    marginRight: theme.spacing(1)
  },
  lightboxWrapper: {
    backgroundColor: 'transparent',
    '& *': {
      backgroundColor: 'transparent'
    }
  },
  mainContainer: {
    paddingTop: theme.spacing(3),
    width: '100%'
  },
  photo: {
    transform: 'translate(0%, -33.4%)',
    width: 'auto',
    height: 'auto',
    minWidth: '100%',
    minHeight: '100%'
  },
  shareIcon: {
    display: 'inline-block',
    margin: theme.spacing(1),
    cursor: 'pointer'
  },
  subContainer: {
    padding: theme.spacing(2)
  }
})

@withWidth()
@withStyles(styles, { withTheme: true })
@injectIntl
@inject('applicationStore')
@observer
class ListingPage extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  }

  state = {
    currentImage: 0,
    endDate: null,
    focusedInput: null,
    guestCountAnchorEl: null,
    lightboxIsOpen: false,
    photos: [],
    pickerAnchorEl: null,
    startDate: null,
    minimumStay: 0,
    mandatoryOptionsOpen: false
  }

  messages = defineMessages({
    guestCount: {
      id: 'ReservationEnLigne.Common.GuestCount',
      defaultMessage: '{adults, plural, =0 {} one {# adult} other {# adults}}|{children, plural, =0 {} one {# child} other {# children}}|{babies, plural, =0 {} one {# baby} other {# babies}}'
    }
  })

  @computed get agency () {
    return this.props.applicationStore.agencies[this.listing.agencyId] || { latitude: 0, longitude: 1, roundingRule: 0 }
  }

  @computed get listing () {
    return this.props.applicationStore.listings.response[this.props.match.params.listingId] || { amenities: [], descriptions: {}, discounts: [], latitude: 1, longitude: 0, photos: [], prices: [], titles: {} }
  }

  @computed get quote () {
    return this.props.applicationStore.quotes[this.props.match.params.listingId] || { state: 'new' }
  }

  @computed get options () {
    return this.props.applicationStore.options[this.props.match.params.listingId] || {}
  }

  quoteAutorun = autorun(() => {
    if (this.quote.state === 'new' &&
      this.props.applicationStore.travelDates.startDate &&
      this.props.applicationStore.travelDates.endDate) {
      const { listingId, agencyId } = this.listing
      const { startDate, endDate } = this.props.applicationStore.travelDates
      this.props.applicationStore.getQuote({
        listingId,
        agencyId,
        startDate,
        endDate
      })
  }
  })

  optionsAutorun = autorun(() => {
    if (this.props.applicationStore.travelDates.startDate &&
      this.props.applicationStore.travelDates.endDate) {
      this.props.applicationStore.getOptions(this.listing.agencyId, this.listing.listingId)
    }
  })

  guestCountAnchorEl = React.createRef()
  pickerAnchorEl = React.createRef()

  checkPickerOutsideRange = day => {
    // If day is before today
    if (day.isBefore(moment())) {
      return true
    }

    const pricingDay = this.state.focusedInput === END_DATE ? moment(day).set({ hours: 0, minutes: 0, seconds: 0 }) : moment(day)
    const pricingIndex = this.listing.prices.findIndex(p => moment.range(p.startDate, p.endDate).contains(pricingDay))

    if (pricingIndex === -1) {
      return true
    }

    if (moment.range(moment(), day).diff('days') < this.listing.prices[pricingIndex].sellStop / 24) {
      return true
    }

    const { startDate } = this.listing.prices[0]
    const { endDate } = this.listing.prices[this.listing.prices.length - 1]
    const allAvailabilities = this.listing.prices.map(p => p.availability).join('')
    const allChangeOvers = this.listing.prices.map(p => p.changeOverDays).join('')
    const currentDayIndex = moment.range(startDate, day).diff('days')

    if (this.state.focusedInput === START_DATE) {
      return allAvailabilities.charAt(currentDayIndex) === 'U' ||
        allChangeOvers.charAt(currentDayIndex) === 'O' ||
        allChangeOvers.charAt(currentDayIndex) === 'X'
    } else if (this.state.focusedInput === END_DATE) {
      const stayStartDate = this.props.applicationStore.travelDates.startDate
      const nextBlockedDayOffset = allAvailabilities.indexOf('U', moment.range(startDate, stayStartDate).diff('days'))
      const nextBlockedDay = nextBlockedDayOffset === -1 ? moment(endDate) : moment(startDate).add(nextBlockedDayOffset, 'days')
      return day.isBefore(stayStartDate, 'day') ||
        day.isAfter(nextBlockedDay, 'day') ||
        allChangeOvers.charAt(currentDayIndex) === 'I' ||
        allChangeOvers.charAt(currentDayIndex) === 'X'
    }

    // Default is blocked, shouldn't be reached
    return true
  }

  handleGuestCountClose = () => {
    this.setState({
      guestCountAnchorEl: null
    })
  }

  handleGuestCountOpen = () => {
    this.setState({
      guestCountAnchorEl: this.guestCountAnchorEl.current
    })
  }

  // Changement des dates dans le calendrier
  handlePickerDatesChange = dates => {
    this.props.applicationStore.handleTravelDatesChange(dates)

    if (this.state.focusedInput === START_DATE && this.props.applicationStore.travelDates.startDate) {
      const stayPrice = this.listing.prices.find(p => moment.range(p.startDate, p.endDate).contains(this.props.applicationStore.travelDates.startDate))
      const minStay = stayPrice.pricingMinimumStay > 0 ? stayPrice.pricingMinimumStay : stayPrice.minimumStay
      this.setState({
        minimumStay: minStay
      })
    }
  }

  handlePickerClose = () => {
    this.setState({
      pickerAnchorEl: null
    })
  }

  handlePickerOpen = focusedInput => () => {
    this.setState({
      pickerAnchorEl: this.pickerAnchorEl.current,
      focusedInput
    })
  }

  handlePickerReset = () => this.setState({
    focusedInput: START_DATE
  }) || this.props.applicationStore.resetTravelDates()

  handlePickerClear = () => {
    this.handlePickerReset()
    this.handlePickerClose()
  }

  handleMandatoryOptionsCollapse = () => {
    this.setState({
      mandatoryOptionsOpen: !this.state.mandatoryOptionsOpen
    })
  }

  handleFacultativeOptionCollapse = (option) => {
    this.setState({
      [option + 'Open']: !this.state[option + 'Open']
    })
  }

  hasDiscount = () =>
    this.quote.state === 'done'
      ? this.quote.pricingDetails.listingDiscount < 0
      : this.listing.discounts.filter(discount => moment.range(discount.publishedFrom, discount.publishedUntil).contains(moment())).length > 0

  handleLightboxOpenClicked = (event, obj) => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: true
    })
  }

  handleLightboxCloseRequest = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    })
  }

  handleLightboxMovePrevRequest = () => {
    this.setState({
      currentImage: (this.state.currentImage + this.listing.photos.length - 1) % this.listing.photos.length
    })
  }

  handleLightboxMoveNextRequest = () => {
    this.setState({
      currentImage: (this.state.currentImage + 1) % this.listing.photos.length
    })
  }

  parseGuestCount = guestCount => {
    return this.props.intl.formatMessage(this.messages.guestCount, guestCount).split('|').filter(n => n).join(', ')
  }

  updateGuestCount = counter => step => () =>
    this.props.applicationStore.updateGuestCount(counter, step)

  componentDidMount () {
    if (this.props.match.params.startDate && this.props.match.params.endDate) {
      when(
        () => this.props.applicationStore.quotes[this.props.match.params.listingId]?.state === 'new',
        () => this.props.applicationStore.handleTravelDatesChange({
          startDate: moment(this.props.match.params.startDate, 'DDMMYYYY').set({ hours: 12, minutes: 0, seconds: 0 }),
          endDate: moment(this.props.match.params.endDate, 'DDMMYYYY').set({ hours: 12, minutes: 0, seconds: 0 })
        })
      )
    }
  }

  render () {
    const amenitiesMiddle = Math.ceil(this.listing.amenities.length / 2)
    // Getting options categories from options
    const optionCategories = []
    const mandatoryOptionCategories = []
    if (this.options.length > 0) {
      if (this.options.filter(opt => !opt.mandatory).length > 0) {
        this.options.filter(opt => !opt.mandatory).map(o => {
          if (!optionCategories.includes(o.category)) {
            optionCategories.push(o.category)
          }
          return null
        })
        optionCategories.sort()
        if (optionCategories.includes('Autres')) {
          const index = optionCategories.indexOf('Autres')
          const value = optionCategories[optionCategories.length - 1]
          optionCategories[optionCategories.length - 1] = 'Autres'
          optionCategories[index] = value
        }
      }

      if (this.options.filter(opt => opt.mandatory).length > 0) {
        this.options.filter(opt => opt.mandatory).map(o => {
          if (!mandatoryOptionCategories.includes(o.category)) {
            mandatoryOptionCategories.push(o.category)
          }
          return null
        })
        mandatoryOptionCategories.sort()
        if (mandatoryOptionCategories.includes('Autres')) {
          const index = mandatoryOptionCategories.indexOf('Autres')
          const value = mandatoryOptionCategories[mandatoryOptionCategories.length - 1]
          mandatoryOptionCategories[mandatoryOptionCategories.length - 1] = 'Autres'
          mandatoryOptionCategories[index] = value
        }
      }
    }
    // const AgencyMarker = new Image()
    // AgencyMarker.height = 64
    // AgencyMarker.width = 64
    // AgencyMarker.src = `data:image/svg+xml;base64,${btoa(agencyMarker(this.props.theme.palette.secondary.main))}`

    // const ListingMarker = new Image()
    // ListingMarker.height = 64
    // ListingMarker.width = 64
    // ListingMarker.src = `data:image/svg+xml;base64,${btoa(listingMarker(this.props.theme.palette.primary.main))}`

    var months
    if (window.innerHeight > 435) {
      months = window.innerWidth < 635 ? 1
        : window.innerWidth < 935 ? 2
          : window.innerWidth < 1235 ? 3
            : window.innerWidth < 1535 ? 4
              : window.innerWidth < 1835 ? 5 : 6
    } else {
      months = window.innerWidth < 850 ? 1
        : window.innerWidth < 1150 ? 2
          : window.innerWidth < 1450 ? 3
            : window.innerWidth < 1750 ? 4
              : window.innerWidth < 2050 ? 5 : 6
    }

    return (
      <div>
        {!isWidthDown('sm', this.props.width) && (
          <Paper elevation={2} style={{ height: '65vh', width: '100%', position: 'relative', overflow: 'hidden' }}>
            <Carousel indicators={false} interval='60000' fade='true'>
              {this.listing.photos.map(listing => (
                <Carousel.Item key={listing.photoId}>
                  <img src={listing.uri} alt={listing.caption} className={this.props.classes.photo} />
                  {/* <Carousel.Caption>
                    <h3>{listing.caption}</h3>
                  </Carousel.Caption> */}
                </Carousel.Item>))}
            </Carousel>
            {this.listing.photos.length > 0
              ? (
                <Fab variant='extended' color='primary' onClick={this.handleLightboxOpenClicked} className={this.props.classes.lightboxButton}>
                  <PhotoLibrary className={this.props.classes.lightboxButtonIcon} />
                  <FormattedMessage
                    defaultMessage='View photos'
                    id='ReservationEnLigne.Components.Listing.ViewPhotos'
                  />
                </Fab>
              ) : ''}
          </Paper>
        )}
        {this.state.lightboxIsOpen &&
          <Lightbox
            mainSrc={this.listing.photos[this.state.currentImage].uri}
            nextSrc={this.listing.photos[(this.state.currentImage + 1) % this.listing.photos.length].uri}
            prevSrc={this.listing.photos[(this.state.currentImage + this.listing.photos.length - 1) % this.listing.photos.length].uri}
            onCloseRequest={this.handleLightboxCloseRequest}
            onMovePrevRequest={this.handleLightboxMovePrevRequest}
            onMoveNextRequest={this.handleLightboxMoveNextRequest}
            currentImage={this.state.currentImage}
            enableZoom={false}
            reactModalStyle={{
              overlay: {
                backgroundColor: 'rgba(0,0,0,0.85)'
              },
              content: {
                marginTop: '64px'
              }
            }}
            wrapperClassName={this.props.classes.lightboxWrapper}
          />}
        <Grid container justify='center' alignItems='stretch' className={this.props.classes.mainContainer} spacing={isWidthDown('sm', this.props.width) ? 0 : 3}>
          <Grid item md={6} xs={12}>
            <Paper className={this.props.classes.subContainer}>
              <Grid container>
                <Grid item md={10} xs={12}>
                  <Typography gutterBottom variant='h4'>
                    {this.listing.titles[this.props.intl.locale.split(/[-_]/)[0]] || this.listing.titles[this.props.intl.defaultLocale]}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography gutterBottom variant='caption' align='right'>
                    {'Référence : ' + this.listing.ref}
                  </Typography>
                </Grid>
              </Grid>
              <div className={this.props.classes.chipArray}>
                {this.listing.officialRanking > 0 && (
                  <Chip color='primary' className={this.props.classes.chip} label={[...Array(this.listing.officialRanking).keys()].map(k => <Star key={k} />)} />
                )}
                <Chip color='primary' avatar={<Avatar><Place /></Avatar>} className={this.props.classes.chip} label={this.listing.city} />
                <Chip color='primary' avatar={<Avatar><MoveResize /></Avatar>} className={this.props.classes.chip} label={`${this.listing.surfaceArea} m²`} />
                <Chip
                  color='primary' avatar={<Avatar><People /></Avatar>} className={this.props.classes.chip} label={
                    <FormattedMessage
                      defaultMessage='{qty, plural, =0 {} one {# guest} other {# guests}}'
                      id='ReservationEnLigne.Components.Listing.Capacity'
                      values={{
                        qty: this.listing.capacity
                      }}
                    />
                  }
                />
                {this.listing.bedrooms > 0 && (
                  <Chip
                    color='primary' avatar={<Avatar><Door /></Avatar>} className={this.props.classes.chip} label={
                      <FormattedMessage
                        defaultMessage='{qty, plural, =0 {} one {# bedroom} other {# bedrooms}}'
                        id='ReservationEnLigne.Components.Listing.Bedrooms'
                        values={{
                          qty: this.listing.bedrooms
                        }}
                      />
                    }
                  />
                )}
                {this.listing.bathrooms > 0 && (
                  <Chip
                    color='primary' avatar={<Avatar><Shower /></Avatar>} className={this.props.classes.chip} label={
                      <FormattedMessage
                        defaultMessage='{qty, plural, =0 {} one {# bathroom} other {# bathrooms}}'
                        id='ReservationEnLigne.Components.Listing.Bathrooms'
                        values={{
                          qty: this.listing.bathrooms
                        }}
                      />
                    }
                  />
                )}
                {this.listing.showerRooms > 0 && (
                  <Chip
                    color='primary' avatar={<Avatar><ShowerHead /></Avatar>} className={this.props.classes.chip} label={
                      <FormattedMessage
                        defaultMessage='{qty, plural, =0 {} one {# shower room} other {# shower rooms}}'
                        id='ReservationEnLigne.Components.Listing.ShowerRooms'
                        values={{
                          qty: this.listing.showerRooms
                        }}
                      />
                    }
                  />
                )}
              </div>
              {this.listing.photos.length > 0 && isWidthDown('sm', this.props.width) &&
                (
                  <Fab variant='extended' color='primary' onClick={this.handleLightboxOpenClicked} style={{ margin: '5px' }}>
                    <PhotoLibrary className={this.props.classes.lightboxButtonIcon} />
                    <FormattedMessage
                      defaultMessage='View photos'
                      id='ReservationEnLigne.Components.Listing.ViewPhotos'
                    />
                  </Fab>
                )}
              <Typography gutterBottom variant='h6' color='textSecondary'>
                <FormattedMessage
                  defaultMessage='Location'
                  id='ReservationEnLigne.Components.Listing.Location'
                />
              </Typography>
              {/* <Map
                containerStyle={{
                  height: '50vh',
                  width: '100%'
                }}
                fitBounds={[
                  [
                    this.agency.longitude,
                    this.agency.latitude
                  ],
                  [
                    this.listing.longitude * 2 - this.agency.longitude,
                    this.listing.latitude * 2 - this.agency.latitude
                  ]
                ]}
                fitBoundsOptions={{
                  padding: this.props.theme.spacing(3)
                }}
                onStyleLoad={map => map.addControl(new FullscreenControl())}
                style='mapbox://styles/mapbox/streets-v10' // eslint-disable-line react/style-prop-object
              >
                  <Layer
                    id='listing'
                    images={['listing-marker', ListingMarker]}
                    layout={{
                      'icon-anchor': 'bottom',
                      'icon-image': 'listing-marker'
                    }}
                    type='symbol'>
                    <Feature coordinates={[this.listing.longitude, this.listing.latitude]}/>
                  </Layer>
                  <Layer
                    id='agency'
                    images={['agency-marker', AgencyMarker]}
                    layout={{
                      'icon-anchor': 'bottom',
                      'icon-image': 'agency-marker'
                    }}
                    type='symbol'>
                    <Feature coordinates={[this.agency.longitude, this.agency.latitude]}/>
                  </Layer>
              </Map> */}
              <Map
                bounds={[
                  [
                    this.agency.latitude,
                    this.agency.longitude
                  ],
                  [
                    this.listing.latitude * 2 - this.agency.latitude,
                    this.listing.longitude * 2 - this.agency.longitude
                  ]
                ]}
                boundsOptions={{
                  padding: [
                    this.props.theme.spacing(3),
                    this.props.theme.spacing(3)
                  ]
                }}
                style={{
                  height: '50vh',
                  width: '100%'
                }}
                scrollWheelZoom={false}
              >
                <TileLayer
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
                />
                <Marker
                  icon={new L.Icon({
                    iconUrl: `data:image/svg+xml;base64,${btoa(listingMarker(this.props.theme.palette.primary.main))}`,
                    iconSize: [64, 64],
                    iconAnchor: [32, 64]
                  })}
                  position={[
                    this.listing.latitude,
                    this.listing.longitude]}
                />
                <Marker
                  icon={new L.Icon({
                    iconUrl: `data:image/svg+xml;base64,${btoa(agencyMarker(this.props.theme.palette.secondary.main))}`,
                    iconSize: [64, 64],
                    iconAnchor: [32, 64]
                  })}
                  position={[
                    this.agency.latitude,
                    this.agency.longitude
                  ]}
                />
              </Map>

              {this.listing.virtualVisitURL && this.listing.virtualVisitURL.length > 0 && (
                <>
                  <Divider style={{ marginBottom: '1em' }} />
                  <Typography gutterBottom variant='h6' color='textSecondary'>
                    <FormattedMessage
                      defaultMessage='Virtual visit'
                      id='ReservationEnLigne.Components.Listing.virtualVisitURL'
                    />
                  </Typography>
                  <Typography paragraph variant='subtitle1' style={{ whiteSpace: 'pre-line' }}>
                    <a href={this.listing.virtualVisitURL}>{this.listing.virtualVisitURL}</a>
                  </Typography>
                </>
              )}

              <Divider style={{ marginBottom: '1em' }} />
              <Typography gutterBottom variant='h6' color='textSecondary'>
                <FormattedMessage
                  defaultMessage='Description'
                  id='ReservationEnLigne.Components.Listing.Description'
                />
              </Typography>
              <Typography paragraph variant='subtitle1' style={{ whiteSpace: 'pre-line' }}>
                {this.listing.descriptions[this.props.intl.locale.split(/[-_]/)[0]] || this.listing.descriptions[this.props.intl.defaultLocale]}
              </Typography>
              <Divider style={{ marginBottom: '1em' }} />
              <Typography gutterBottom variant='h6' color='textSecondary'>
                <FormattedMessage
                  defaultMessage='Amenities'
                  id='ReservationEnLigne.Components.Listing.Amenities'
                />
              </Typography>
              <Grid container justify='center' alignItems='stretch'>
                <Grid item xs={6}>
                  <List>
                    {
                      this.listing.amenities.slice(0, amenitiesMiddle).map((amenity, i) => (
                        <ListItem key={i}>
                          <ListItemText>
                            {/* <i className={'mid mdi-24px mdi-'+amenity.icon}></i> */}
                            <Chip color='secondary' avatar={<Avatar />} className={this.props.classes.chip} label={amenity.names[this.props.intl.locale.split(/[-_]/)[0]] || amenity.names[this.props.intl.defaultLocale]} />
                          </ListItemText>
                        </ListItem>
                      ))
                    }
                  </List>

                  {this.listing.swimmingPool && this.listing.swimmingPoolType < 1 && (
                    <>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Chip
                              color='secondary' avatar={<Avatar />} className={this.props.classes.chip} label={
                                <FormattedMessage
                                  defaultMessage='Swimming pool'
                                  id='ReservationEnLigne.Components.Listing.SwimmingPool'
                                />
                              }
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </>
                  )}
                  {this.listing.swimmingPool && this.listing.swimmingPoolType === 1 && (
                    <>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Chip
                              color='secondary' avatar={<Avatar />} className={this.props.classes.chip} label={
                                <FormattedMessage
                                  defaultMessage='Individual swimming pool'
                                  id='ReservationEnLigne.Components.Listing.SwimmingPoolTypeCol'
                                />
                              }
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </>
                  )}
                  {this.listing.swimmingPool && this.listing.swimmingPoolType === 2 && (
                    <>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <Chip
                              color='secondary' avatar={<Avatar />} className={this.props.classes.chip} label={
                                <FormattedMessage
                                  defaultMessage='Private swimming pool'
                                  id='ReservationEnLigne.Components.Listing.SwimmingPoolTypeInd'
                                />
                              }
                            />
                          </ListItemText>
                        </ListItem>
                      </List>
                    </>
                  )}

                </Grid>
                <Grid item xs={6}>
                  <List>
                    {
                      this.listing.amenities.slice(amenitiesMiddle).map((amenity, i) => (
                        <ListItem key={i}>
                          <ListItemText color='secondary'>
                            {/* <i className={'mid mdi-24px mdi-'+amenity.icon}></i> */}
                            <Chip color='secondary' avatar={<Avatar />} className={this.props.classes.chip} label={amenity.names[this.props.intl.locale.split(/[-_]/)[0]] || amenity.names[this.props.intl.defaultLocale]} />
                          </ListItemText>
                        </ListItem>
                      ))
                    }
                  </List>
                </Grid>
              </Grid>
              {this.listing.proximities && this.listing.proximities.length > 0 && (
                <>
                  <Divider style={{ marginBottom: '1em' }} />
                  <Typography gutterBottom variant='h6' color='textSecondary'>
                    <FormattedMessage
                      defaultMessage='Proximities'
                      id='ReservationEnLigne.Components.Listing.Proximities'
                    />
                  </Typography>
                  <Table>
                    <TableBody>
                      {this.listing.proximities.map((p, n) => {
                        return (
                          <TableRow key={n}>
                            <TableCell>{p.name}</TableCell>
                            <TableCell>{p.distance + ' ' + p.unit}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
              {optionCategories.length > 0 &&
              (
                <>
                  <Divider style={{ marginBottom: '1em' }} />
                  <Typography gutterBottom variant='h6' color='textSecondary'>
                    <FormattedMessage
                      defaultMessage='Options'
                      id='ReservationEnLigne.Components.Listing.FacultativeOptions'
                    />
                  </Typography>
                  {optionCategories.map((cat, n) => {
                    if (this.state[n + 'Open'] === undefined) {
                      this.setState({
                        [n + 'Open']: true
                      })
                    }
                    return (
                      <Card key={n} style={{ marginBottom: '1%' }}>
                        <Card.Header onClick={e => this.handleFacultativeOptionCollapse(n)} style={{ cursor: 'pointer', borderRadius: '0.25rem' }}>
                          {cat}
                          <span style={{ float: 'right' }}>{this.state[n + 'Open'] ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />)}</span>
                        </Card.Header>
                        <Card.Body style={{
                          visibility: this.state[n + 'Open'] ? 'visible' : 'collapse',
                          opacity: this.state[n + 'Open'] ? 1 : 0,
                          padding: this.state[n + 'Open'] ? '1.25rem' : 0,
                          transition: 'visibility 300ms, opacity 300ms, padding 300ms'
                        }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <FormattedMessage
                                    defaultMessage='Option'
                                    id='ReservationEnLigne.Components.Listing.Option'
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormattedMessage
                                    defaultMessage='Price'
                                    id='ReservationEnLigne.Components.Home.Price'
                                  />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.options.filter(opt => opt.category === cat && !opt.mandatory).map((opt, i) => {
                                return (
                                  <TableRow key={i}>
                                    <TableCell>{opt.name}</TableCell>
                                    <TableCell style={{ width: '250px' }}>
                                      {/* eslint-disable-next-line react/style-prop-object */}
                                      <FormattedNumber style='currency' currency='EUR' value={opt.unitPrice} />
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </Card.Body>
                      </Card>
                    )
                  })}
                </>
              )}
              {/*
              <Divider style={{ margin: '1em 0' }} />
              <Typography gutterBottom variant='h6' color='textSecondary'>
                <FormattedMessage
                  defaultMessage='Share'
                  id='ReservationEnLigne.Components.Listing.Share'
                />
              </Typography>
              <EmailShareButton url={window.location.href} className={this.props.classes.shareIcon}>
                <EmailIcon size={48} round />
              </EmailShareButton>
              <Badge className={this.props.classes.shareIcon} badgeContent={(<FacebookShareCount url={window.location.href} />)} max={9999} color='primary'>
                <FacebookShareButton url={window.location.href}>
                  <FacebookIcon size={48} round />
                </FacebookShareButton>
              </Badge>
              <TwitterShareButton url={window.location.href} className={this.props.classes.shareIcon}>
                <TwitterIcon size={48} round />
              </TwitterShareButton>
              <WhatsappShareButton url={window.location.href} className={this.props.classes.shareIcon}>
                <WhatsappIcon size={48} round />
              </WhatsappShareButton>
              <Badge className={this.props.classes.shareIcon} badgeContent={(<TumblrShareCount url={window.location.href} />)} max={9999} color='primary'>
                <TumblrShareButton url={window.location.href}>
                  <TumblrIcon size={48} round />
                </TumblrShareButton>
              </Badge>
              <TelegramShareButton url={window.location.href} className={this.props.classes.shareIcon}>
                <TelegramIcon size={48} round />
              </TelegramShareButton>
              */}
            </Paper>
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <Paper className={this.props.classes.subContainer}>
              <Grid container direction='column' alignItems='stretch' spacing={2}>
                <Grid item>
                  {this.quote.state === 'new' && (
                    <Typography variant='caption'>
                      <FormattedMessage
                        defaultMessage='Accommodation from'
                        id='ReservationEnLigne.Components.Listing.From'
                      />
                    </Typography>
                  )}
                  {this.quote.state === 'pending' ? (
                    <ContentLoader
                      height={48}
                      width={200}
                      speed={2}
                      backgroundColor={this.props.theme.palette.primary.light}
                      foregroundColor={this.props.theme.palette.primary.dark}
                      style={{ height: '1.625rem' }}
                    >
                      <rect x='0' y='0' rx='10' ry='10' width='200' height='48' />
                    </ContentLoader>
                  ) : ((this.quote.state === 'new' || this.quote.available) ? (
                    <>
                      <Typography variant='h6' style={{ display: 'inline-block', marginRight: '0.25rem' }} className={this.hasDiscount() ? this.props.classes.crossedOut : ''}>
                        {/* valeur négative ou zéro en erreur */}
                        {((this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice))) <= 0) && (
                          <FormattedMessage
                          defaultMessage='NC'
                          id='ReservationEnLigne.Components.Listing.NC'
                          />
                        )}
                        {/* Pas d arrondi => comme avant */}
                        {this.agency.roundingRule === 0 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                          // eslint-disable-next-line react/style-prop-object
                          <FormattedNumber style='currency' currency='EUR' value={this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice))} />
                        )}
                        {/* Arrondi à la dizaine de centimes sup */}
                        {this.agency.roundingRule === 1 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                          // eslint-disable-next-line react/style-prop-object
                          <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice))) * 10)) / 10} />
                        )}
                        {/* Arrondis à l'euro sup */}
                        {this.agency.roundingRule === 2 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                          // eslint-disable-next-line react/style-prop-object
                          <FormattedNumber style='currency' currency='EUR' value={Math.ceil(this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)))} />
                        )}
                        {/* Arrondis à la dizaine d'euros sup */}
                        {this.agency.roundingRule === 3 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                          // eslint-disable-next-line react/style-prop-object
                          <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice))) / 10)) * 10} />
                        )}
                      </Typography>
                      {
                        this.hasDiscount() &&
                          <Typography variant='h6' color='secondary' style={{ display: 'inline-block', marginRight: '0.25rem' }}>
                            {/* Pas d arrondi => comme avant */}
                            {this.agency.roundingRule === 0 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                              // eslint-disable-next-line react/style-prop-object
                              <FormattedNumber style='currency' currency='EUR' value={this.quote.totalPrice || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.listing.discounts.map(p => 1 - (p.rate / 100)))} />
                            )}
                            {/* Arrondi à la dizaine de centimes sup */}
                            {this.agency.roundingRule === 1 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                              // eslint-disable-next-line react/style-prop-object
                              <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.listing.discounts.map(p => 1 - (p.rate / 100)))) * 10)) / 10} />
                            )}
                            {/* Arrondis à l'euro sup */}
                            {this.agency.roundingRule === 2 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                              // eslint-disable-next-line react/style-prop-object
                              <FormattedNumber style='currency' currency='EUR' value={Math.ceil(this.quote.totalPrice || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.listing.discounts.map(p => 1 - (p.rate / 100))))} />
                            )}
                            {/* Arrondis à la dizaine d'euros sup */}
                            {this.agency.roundingRule === 3 && (this.quote.totalPrice - this.quote.pricingDetails?.listingDiscount || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) > 0) && (
                              // eslint-disable-next-line react/style-prop-object
                              <FormattedNumber style='currency' currency='EUR' value={(Math.ceil((this.quote.totalPrice || Math.min(...this.listing.prices.map(p => p.weeklyPrice)) * Math.max(...this.listing.discounts.map(p => 1 - (p.rate / 100)))) / 10)) * 10} />
                            )}
                          </Typography>
                      }
                    </>
                  ) : (
                    <Typography variant='h6' style={{ display: 'inline-block', marginRight: '0.25rem' }}>
                      <FormattedMessage
                        defaultMessage='Unavailable'
                        id='ReservationEnLigne.Components.Listing.Unavailable'
                      />
                    </Typography>
                  ))}
                  {this.quote.state === 'new' && (
                    <Typography variant='caption'>
                      <FormattedMessage
                        defaultMessage='per week'
                        id='ReservationEnLigne.Components.Listing.PerWeek'
                      />
                    </Typography>
                  )}
                  {/* {this.quote.state === 'done' && this.quote.available && (
                    <Typography variant='caption'>
                      <FormattedMessage
                        defaultMessage='all inclusive'
                        id='ReservationEnLigne.Components.Listing.AllInclusive'
                      />
                    </Typography>
                  )} */}
                </Grid>
                <Grid item>
                  {this.quote.state === 'done' && this.quote.available && this.quote.pricingDetails.earlyLast > 0 && (
                    <Chip
                      style={{ backgroundColor: '#dc3545', color: '#ffffff', display: 'grid' }} label={
                        this.quote.pricingDetails.earlyLast === 1 ? (
                          <FormattedMessage
                            defaultMessage='Early Booking'
                            id='ReservationEnLigne.Components.Listing.Early'
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage='Last Minute'
                            id='ReservationEnLigne.Components.Listing.LastMinute'
                          />)
                      }
                    />
                  )}
                </Grid>
                <Grid item container wrap='nowrap' alignItems='center' justify='center'>
                  <Grid item xs={5}>
                    <div ref={this.pickerAnchorEl}>
                      <TextField
                        readOnly
                        fullWidth
                        id='startDate'
                        label={
                          <FormattedMessage
                            defaultMessage='From'
                            id='ReservationEnLigne.Components.Home.From'
                          />
                        }
                        type='text'
                        value={this.props.applicationStore.travelDates.startDate ? this.props.applicationStore.travelDates.startDate.format('L') : ''}
                        helperText={
                          <FormattedMessage
                            defaultMessage='Arrival after {time}'
                            id='ReservationEnLigne.Components.Home.FromHelper'
                            values={{
                              time: moment(this.props.applicationStore.agencies[this.listing.agencyId]?.minArrivalTime || '16:00:00', 'HH:mm:ss').format('LT')
                            }}
                          />
                        }
                        onFocus={this.handlePickerOpen(START_DATE)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs>
                    <div style={{ width: '100%' }}>
                      <ArrowForward color='primary' style={{ bottom: 0, display: 'block', height: '100%', margin: 'auto' }} />
                    </div>
                    <Modal
                      disableRestoreFocus open={Boolean(this.state.pickerAnchorEl) && this.state.focusedInput !== null}
                      onClose={this.handlePickerClose}
                    >
                      <Paper className={this.props.classes.datePickerPaper}>
                        <div>
                          <TranslatedDayPickerRangeController
                            numberOfMonths={months}
                            startDate={this.props.applicationStore.travelDates.startDate}
                            endDate={this.props.applicationStore.travelDates.endDate}
                            focusedInput={this.state.focusedInput}
                            minimumNights={this.state.minimumStay}
                            isOutsideRange={this.checkPickerOutsideRange}
                            onDatesChange={this.handlePickerDatesChange}
                            daySize={(isWidthUp('sm', this.props.width) && isWidthDown('sm', this.props.width)) ? 36 : 39}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                          />
                        </div>
                        <div>
                          <Button variant='contained' color='primary' onClick={this.handlePickerClose} style={{ width: isWidthDown('xs', this.props.width) ? '80%' : 'auto' }}>
                            <Done />&nbsp;
                            <FormattedMessage
                              defaultMessage='Confirm selection'
                              id='ReservationEnLigne.Components.DatePicker.Confirm'
                            />
                          </Button>
                          <Button variant='contained' color='secondary' onClick={this.handlePickerReset} style={{ width: isWidthDown('xs', this.props.width) ? '80%' : 'auto' }}>
                            <Delete />&nbsp;
                            <FormattedMessage
                              defaultMessage='Clear selection'
                              id='ReservationEnLigne.Components.DatePicker.Clear'
                            />
                          </Button>
                          <Button variant='contained' color='secondary' onClick={this.handlePickerClear} style={{ width: isWidthDown('xs', this.props.width) ? '80%' : 'auto' }}>
                            <Clear />&nbsp;
                            <FormattedMessage
                              defaultMessage='Clear and close'
                              id='ReservationEnLigne.Components.DatePicker.Close'
                            />
                          </Button>
                        </div>
                      </Paper>
                    </Modal>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      readOnly
                      fullWidth
                      id='endDate'
                      label={
                        <FormattedMessage
                          defaultMessage='To'
                          id='ReservationEnLigne.Components.Home.To'
                        />
                      }
                      type='text'
                      value={this.props.applicationStore.travelDates.endDate ? this.props.applicationStore.travelDates.endDate.format('L') : ''}
                      helperText={
                        <FormattedMessage
                          defaultMessage='Departure before {time}'
                          id='ReservationEnLigne.Components.Home.ToHelper'
                          values={{
                            time: moment(this.props.applicationStore.agencies[this.listing.agencyId]?.maxDepartureTime || '10:00:00', 'HH:mm:ss').format('LT')
                          }}
                        />
                      }
                      onFocus={this.handlePickerOpen(END_DATE)}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <div ref={this.guestCountAnchorEl}>
                    <TextField
                      fullWidth
                      readOnly
                      id='guestCount'
                      label={
                        <FormattedMessage
                          defaultMessage='Guests'
                          id='ReservationEnLigne.Components.Home.Guests'
                        />
                      }
                      type='text'
                      value={this.parseGuestCount(this.props.applicationStore.guestCount)}
                      helperText={
                        <FormattedMessage
                          defaultMessage='How many beds will you need ?'
                          id='ReservationEnLigne.Components.Home.GuestsHelper'
                        />
                      }
                      onFocus={this.handleGuestCountOpen}
                    />
                  </div>
                  <Popover disableRestoreFocus open={Boolean(this.state.guestCountAnchorEl)} anchorEl={this.state.guestCountAnchorEl} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} onClose={this.handleGuestCountClose}>
                    <Grid container wrap='nowrap' direction='column' alignItems='stretch' className={this.props.classes.guestCount} spacing={3}>
                      <Grid item xs>
                        <Spinner value={this.props.applicationStore.guestCount.adults} onUpdate={this.updateGuestCount('adults')} minVal={1} maxVal={this.listing.capacity - this.props.applicationStore.guestCount.children}>
                          <Typography variant='subtitle1' color='inherit'>
                            Adultes
                          </Typography>
                          <Typography variant='caption' color='inherit'>
                            + de 18 ans
                          </Typography>
                        </Spinner>
                      </Grid>
                      <Grid item xs>
                        <Spinner value={this.props.applicationStore.guestCount.children} onUpdate={this.updateGuestCount('children')} maxVal={this.listing.capacity - this.props.applicationStore.guestCount.adults}>
                          <Typography variant='subtitle1' color='inherit'>
                            Enfants
                          </Typography>
                          <Typography variant='caption' color='inherit'>
                            - de 18 ans
                          </Typography>
                        </Spinner>
                      </Grid>
                      <Grid item xs>
                        <Spinner value={this.props.applicationStore.guestCount.babies} onUpdate={this.updateGuestCount('babies')} maxVal={4}>
                          <Typography variant='subtitle1' color='inherit'>
                            Bébés
                          </Typography>
                          <Typography variant='caption' color='inherit'>
                            Besoin de lit bébé
                          </Typography>
                        </Spinner>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
                {this.quote.state === 'done' && this.quote.available && (
                  <>
                    <Divider />
                    <Grid item>
                      <Table padding='dense'>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <FormattedMessage
                                defaultMessage='Base price'
                                id='ReservationEnLigne.Components.Listing.ListingInitialPrice'
                              />
                            </TableCell>
                            <TableCell align='right'>
                              {/* Pas d arrondi => comme avant */}
                              {this.agency.roundingRule === 0 && (
                                // eslint-disable-next-line react/style-prop-object
                                <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.listingInitialPrice} />
                              )}
                              {/* Arrondi à la dizaine de centimes sup */}
                              {this.agency.roundingRule === 1 && (
                                // eslint-disable-next-line react/style-prop-object
                                <FormattedNumber style='currency' currency='EUR' value={(Math.ceil(this.quote.pricingDetails.listingInitialPrice * 10)) / 10} />
                              )}
                              {/* Arrondis à l'euro sup */}
                              {this.agency.roundingRule === 2 && (
                                // eslint-disable-next-line react/style-prop-object
                                <FormattedNumber style='currency' currency='EUR' value={Math.ceil(this.quote.pricingDetails.listingInitialPrice)} />
                              )}
                              {/* Arrondis à la dizaine d'euros sup */}
                              {this.agency.roundingRule === 3 && (
                                // eslint-disable-next-line react/style-prop-object
                                <FormattedNumber style='currency' currency='EUR' value={(Math.ceil(this.quote.pricingDetails.listingInitialPrice / 10)) * 10} />
                              )}
                            </TableCell>
                          </TableRow>
                          {this.quote.pricingDetails.listingDiscount < 0 && (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <FormattedMessage
                                  defaultMessage='Discount'
                                  id='ReservationEnLigne.Components.Listing.ListingDiscount'
                                />
                                &nbsp;(
                                {this.quote.pricingDetails.earlyLast > 0 && (
                                  this.quote.pricingDetails.earlyLast === 1 ? (
                                    <FormattedMessage
                                      defaultMessage='Early Booking'
                                      id='ReservationEnLigne.Components.Listing.Early'
                                    />
                                  ) : (
                                    <FormattedMessage
                                      defaultMessage='Last Minute'
                                      id='ReservationEnLigne.Components.Listing.LastMinute'
                                    />
                                  )
                                )}
                                )
                              </TableCell>
                              <TableCell align='right' style={{ color: this.props.theme.palette.secondary.main }}>
                                {/* eslint-disable-next-line react/style-prop-object */}
                                <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.listingDiscount} />
                              </TableCell>
                            </TableRow>
                          )}
                          {this.quote.pricingDetails.cleaningFee > 0 && (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <FormattedMessage
                                  defaultMessage='Cleaning fee'
                                  id='ReservationEnLigne.Components.Listing.CleaningFee'
                                />
                              </TableCell>
                              <TableCell align='right'>
                                {/* eslint-disable-next-line react/style-prop-object */}
                                <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.cleaningFee} />
                              </TableCell>
                            </TableRow>
                          )}
                          {this.quote.pricingDetails.processingFee > 0 && (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <FormattedMessage
                                  defaultMessage='Processing fee'
                                  id='ReservationEnLigne.Components.Listing.ProcessingFee'
                                />
                              </TableCell>
                              <TableCell align='right'>
                                {/* eslint-disable-next-line react/style-prop-object */}
                                <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.processingFee} />
                              </TableCell>
                            </TableRow>
                          )}
                          {this.quote.pricingDetails.mandatoryOptionsCost > 0 && (
                            <TableRow onClick={this.handleMandatoryOptionsCollapse} style={{ cursor: 'pointer' }}>
                              <TableCell colSpan={2}>
                                <FormattedMessage
                                  defaultMessage='Mandatory options'
                                  id='ReservationEnLigne.Components.Listing.MandatoryOptionsCost'
                                />
                                {this.state.mandatoryOptionsOpen ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />)}
                              </TableCell>
                              <TableCell align='right'>
                                {/* eslint-disable-next-line react/style-prop-object */}
                                <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.mandatoryOptionsCost} />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                        <TableBody style={{
                          visibility: this.state.mandatoryOptionsOpen ? 'visible' : 'collapse',
                          opacity: this.state.mandatoryOptionsOpen ? 1 : 0,
                          transition: 'visibility 300ms, opacity 300ms'
                        }}
                        >
                          {mandatoryOptionCategories.length > 0
                            ? mandatoryOptionCategories.map((cat, n) =>
                              this.options.filter(opt => opt.category === cat && opt.mandatory).length > 0
                                ? this.options.filter(opt => opt.category === cat && opt.mandatory).map((o, n) => {
                                  return (
                                    <TableRow key={n}>
                                      <TableCell colSpan={1} style={{ fontSize: '11px' }}>
                                        {n === 0 ? cat : ''}
                                      </TableCell>
                                      <TableCell colSpan={1} style={{ fontSize: '11px' }}>
                                        {o.name}
                                      </TableCell>
                                      <TableCell align='right' style={{ fontSize: '11px' }}>
                                        {/* eslint-disable-next-line react/style-prop-object */}
                                        <FormattedNumber style='currency' currency='EUR' value={o.unitPrice} />
                                      </TableCell>
                                    </TableRow>
                                  )
                                }) : <></>
                            ) : <></>}
                        </TableBody>
                        <TableBody>
                          {this.quote.pricingDetails.cancellationInsuranceFee > 0 && (
                            <TableRow style={{ fontStyle: 'italic' }}>
                              <TableCell colSpan={2}>
                                <FormattedMessage
                                  defaultMessage='Cancellation insurance (optional)'
                                  id='ReservationEnLigne.Components.Listing.CancellationInsuranceFee'
                                />
                              </TableCell>
                              <TableCell align='right'>
                                {/* eslint-disable-next-line react/style-prop-object */}
                                <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.cancellationInsuranceFee} />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                    <Grid item>
                      <Button fullWidth variant='contained' color='primary' component={Link} to={{ pathname: '/book', state: { listingId: this.listing.listingId } }}>
                        <FormattedMessage
                          defaultMessage='Book now!'
                          id='ReservationEnLigne.Components.Listing.BookNow'
                        />
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ListingPage
