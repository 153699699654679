// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Packages
import {
  Typography,
  withStyles
} from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
const handleStyle = theme => {
  const colors = {
    primary: theme.palette.primary.main,
    thumbOutline: fade(theme.palette.primary.main, 0.16)
  }

  return {
    common: {
      position: 'absolute',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)'
    },
    upper: {
      zIndex: 5,
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: '30px solid transparent',
      transform: 'translate(-50%, -85%)',
      cursor: 'pointer',
      backgroundColor: 'none'
    },
    lower: {
      zIndex: 5,
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderBottom: '30px solid transparent',
      transform: 'translate(-50%, -15%)',
      cursor: 'pointer',
      backgroundColor: 'none'
    },
    inner: {
      zIndex: 2,
      width: 12,
      height: 12,
      transform: 'translate(-50%, -50%)',
      borderRadius: '50%',
      backgroundColor: colors.primary
    },
    active: {
      boxShadow: `0px 0px 0px 16px ${colors.thumbOutline}`
    },
    value: {
      zIndex: 10,
      fontSize: 11,
      marginTop: -20,
      transform: 'translate(-50%, -85%)'
    }
  }
}

@withStyles(handleStyle)
@observer
class Handle extends Component {
  static propTypes = {
    activeHandleID: PropTypes.string,
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired
    }).isRequired,
    classes: PropTypes.object.isRequired,
    getHandleProps: PropTypes.func.isRequired
  }

  render () {
    const {
      activeHandleID,
      domain: [min, max],
      handle: { id, value, percent },
      classes,
      getHandleProps
    } = this.props

    const active = activeHandleID === id

    return (
      <>
        <div
          className={clsx(classes.common, classes.upper)}
          style={{ left: `${percent}%` }}
          {...getHandleProps(id)}
        />
        <div
          className={clsx(classes.common, classes.lower)}
          style={{ left: `${percent}%` }}
          {...getHandleProps(id)}
        />
        <div
          role='slider'
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          className={clsx(
            classes.common,
            classes.inner,
            active && classes.active
          )}
          style={{ left: `${percent}%` }}
        />
        <div
          className={clsx(classes.common, classes.value)}
          style={{ left: `${percent}%` }}
        >
          {value}
        </div>
      </>
    )
  }
}

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// *******************************************************
const keyboardHandleStyle = theme => ({
  root: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width: 24,
    height: 24,
    cursor: 'pointer',
    borderRadius: '50%',
    boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.palette.primary.main
  }
})

@withStyles(keyboardHandleStyle)
@observer
class KeyboardHandle extends Component {
  static propTypes = {
    domain: PropTypes.array.isRequired,
    handle: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired
    }).isRequired,
    classes: PropTypes.object.isRequired,
    getHandleProps: PropTypes.func.isRequired
  }

  render () {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      classes,
      getHandleProps
    } = this.props

    return (
      <button
        role='slider'
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        className={classes.root}
        style={{ left: `${percent}%` }}
        {...getHandleProps(id)}
      />
    )
  }
}

// *******************************************************
// RAIL COMPONENT
// *******************************************************
const railStyle = () => ({
  common: {
    position: 'absolute',
    width: '100%',
    transform: 'translate(0%, -50%)'
  },
  outer: {
    height: 42,
    borderRadius: 21,
    cursor: 'pointer',
    border: '1px solid white'
  },
  inner: {
    height: 4,
    borderRadius: 2,
    pointerEvents: 'none',
    backgroundColor: 'rgb(155,155,155)'
  }
})

@withStyles(railStyle)
@observer
class SliderRail extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    getRailProps: PropTypes.func.isRequired
  }

  render () {
    const {
      classes,
      getRailProps
    } = this.props

    return (
      <>
        <div
          className={clsx(classes.common, classes.outer)}
          {...getRailProps()}
        />
        <div className={clsx(classes.common, classes.inner)} />
      </>
    )
  }
}

// *******************************************************
// TICK COMPONENT
// *******************************************************

const tickStyle = theme => ({
  tick: {
    position: 'absolute',
    marginTop: 10,
    width: 1,
    height: 5,
    backgroundColor: theme.palette.text.primary
  },
  label: {
    position: 'absolute',
    marginTop: 16,
    textAlign: 'center'
  }
})

@withStyles(tickStyle)
@observer
class Tick extends Component {
  static propTypes = {
    tick: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired
    }).isRequired,
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    format: PropTypes.func.isRequired
  }

  static defaultProps = {
    format: d => d
  }

  render () {
    const {
      classes,
      tick,
      count,
      format
    } = this.props

    return (
      <div>
        <div className={classes.tick} style={{ left: `${tick.percent}%` }} />
        <Typography
          className={classes.label}
          style={{
            marginLeft: `${-(100 / count) / 2}%`,
            width: `${100 / count}%`,
            left: `${tick.percent}%`
          }}
        >
          {format(tick.value)}
        </Typography>
      </div>
    )
  }
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
const trackStyle = theme => ({
  root: {
    position: 'absolute',
    transform: 'translate(0%, -50%)',
    height: 4,
    zIndex: 1,
    borderRadius: 2,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main
  }
})

@withStyles(trackStyle)
@observer
class Track extends Component {
  static propTypes = {
    source: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired
    }).isRequired,
    target: PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      percent: PropTypes.number.isRequired
    }).isRequired,
    classes: PropTypes.object.isRequired,
    getTrackProps: PropTypes.func.isRequired
  }

  render () {
    const {
      classes,
      source,
      target,
      getTrackProps
    } = this.props

    return (
      <div
        className={classes.root}
        style={{
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`
        }}
        {...getTrackProps()}
      />
    )
  }
}

const style = () => ({
  label: {
    textAlign: 'center',
    transform: 'translateY(-150%)'
  },
  root: {
    padding: '0 1em',
    width: '100%'
  },
  slider: {
    position: 'relative',
    width: '100%'
  }
})

@withStyles(style)
@observer
class AIOSlider extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    domain: PropTypes.array.isRequired,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    values: PropTypes.array.isRequired
  }

  render () {
    const {
      classes,
      domain,
      label,
      onChange,
      values,
      ...passProps
    } = this.props

    return (
      <div className={classes.root}>
        <Typography className={classes.label}>
          {label}
        </Typography>
        <Slider
          mode={2}
          step={1}
          domain={domain}
          className={classes.slider}
          onChange={onChange}
          values={values}
          {...passProps}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ activeHandleID, handles, getHandleProps }) => (
              <div>
                {handles.map(handle => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={domain}
                    activeHandleID={activeHandleID}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks values={[...domain, Math.round(domain.reduce((acc, val) => acc + val) / 2.0)].sort()}>
            {({ ticks }) => (
              <div>
                {ticks.map(tick => (
                  <Tick key={tick.id} tick={tick} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    )
  }
}

export default AIOSlider

export {
  Handle,
  KeyboardHandle,
  SliderRail,
  Tick,
  Track
}
