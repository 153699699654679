// React
import React from 'react'
import ReactDOM from 'react-dom'

// Packages
import '@fmauneko/react-dates/initialize'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import moment from 'moment'
import ReactGA from 'react-ga'
import { IntlProvider } from 'react-intl'
import { Router } from 'react-router-dom'
import 'typeface-roboto'

// Internal
import App from './components/App'
import { ApplicationStore } from './stores'
import * as serviceWorker from './serviceWorker'

// Assets
import '@fmauneko/react-dates/lib/css/_datepicker.css'
import './index.css'
import * as messages from './assets/i18n'

Sentry.init({
  dsn: 'https://b765f66a2c5e48c397a52a2ae95cda30@sentry.heliosconnect.fr/11',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  release: `${process.env.REACT_APP_VERSION}${process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_GIT_HEAD.substring(0, 7) : ''}`,
  sendDefaultPii: true
})

// Load moment locale
try {
  require(`moment/locale/${navigator.language}`)
  moment.locale(navigator.language)
} catch {
  try {
    require(`moment/locale/${navigator.language.split(/[-_]/)[0]}`)
    moment.locale(navigator.language)
    document.documentElement.lang = navigator.language.split(/[-_]/)[0]
  } catch {
    moment.locale('en')
    document.documentElement.lang = navigator.language.split(/[-_]/)[0]
  }
}

// Load react-intl locale
// addLocaleData(require(`react-intl/locale-data/${navigator.language.split(/[-_]/)[0]}`))

const applicationStore = new ApplicationStore()

const history = createBrowserHistory()

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
  ReactGA.pageview(window.location.pathname)

  history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  })
}

const ReservationEnLigne = () => {
  return (
    <IntlProvider locale={navigator.language} messages={messages[navigator.language.split(/[-_]/)[0]]}>
      <Router history={history}>
        <Provider applicationStore={applicationStore}>
          <App />
        </Provider>
      </Router>
    </IntlProvider>
  )
}

ReactDOM.render(
  <ReservationEnLigne />,
  document.getElementById('root')
)

serviceWorker.unregister()
