// React
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Packages
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField as MuiTextField,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@material-ui/icons'
import withWidth, {
  isWidthDown
} from '@material-ui/core/withWidth'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { action, autorun, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { FormattedMessage, FormattedNumber, defineMessages, injectIntl } from 'react-intl'
import * as yup from 'yup'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { TransferWithinAStationSharp } from '@material-ui/icons'
const moment = extendMoment(Moment)

const styles = theme => ({
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  optionQuantityButton: {
    margin: '10px auto',
    minWidth: 0,
    height: '40px',
    width: '35px',
    fontSize: '19px'
  },
  optionQuantityContainer: {
    '@media (min-width: 650px)': {
      whiteSpace: 'nowrap'
    }
  },
  mainContainer: {
    paddingTop: theme.spacing(3),
    width: '100%'
  },
  subContainer: {
    padding: theme.spacing(2)
  }
})

@withWidth()
@withStyles(styles, { withTheme: true })
@injectIntl
@inject('applicationStore')
@observer
class BookPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  }

  state = {
    activeStep: 0,
    cancellationInsuranceSubscribed: true,
    termsAccepted: false,
    bookingResponse: '',
    paymentChoice: 'option',
    paymentStatus: '',
    optionsChecked: {},
    facultativeOptionsOpen: false,    
    mandatoryOptionsOpen: false   ,
    PayAll: false
  }


  messages = defineMessages({
    atLeastOnePhoneNumber: {
      id: 'ReservationEnLigne.Components.Book.AtLeastOnePhoneNumber',
      defaultMessage: 'At least one of the phone numbers is required.'
    },
    requiredField: {
      id: 'ReservationEnLigne.Components.Book.RequiredField',
      defaultMessage: 'This field is required.'
    }
  })

  @computed get agency () {
    return this.props.applicationStore.agencies[this.listing.agencyId] || { securePaymentAvailable: false }
  }

  @computed get listing () {
    return this.props.applicationStore.listings.response[this.props.location.state.listingId] || { amenities: [], discounts: [], photos: [], prices: [] }
  }

  @computed get quote () {
    
    return this.props.applicationStore.quotes[this.props.location.state.listingId] || { state: 'new' }
    
  }

  @computed get options () {
    return this.props.applicationStore.options[this.props.location.state.listingId] || {}
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }))
    window.scrollTo(0, 0)
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }))
    window.scrollTo(0, 0)
  }

    
  rateDiscount = () =>
    this.quote.state === 'done'
      ? this.quote.pricingDetails.listingDiscount < 0
      : this.listing.discounts.filter(discount => moment.range(discount.publishedFrom, discount.publishedUntil).contains(moment())).length > 0

  handlePayment = () => {
    var CumulOptionForSold = 0
    this.props.applicationStore.createBooking(this.props.location.state.listingId, this.state.cancellationInsuranceSubscribed, this.listing.discounts.map(p => p.rate)).then(() => {
      if (this.props.applicationStore.booking.state !== 'error') {
        const id = this.props.applicationStore.booking.response.bookingNumber
        console.log(Object.keys(this.state.optionsChecked));
        Object.keys(this.state.optionsChecked).forEach(o => {          
          if (this.state.optionsChecked[o] > 0) {            
            const CurOption = this.options.filter(opt => opt.optionID === o)
            CumulOptionForSold += ( CurOption[0].unitPrice * this.state.optionsChecked[o] )                                     
            this.props.applicationStore.addOption(id, this.options.filter(opt => opt.optionID === o)[0], this.state.optionsChecked[o])
          }
        })
       
        //let nbdays = moment(this.quote.startDate).diff(Date.Now, 'days')  
        if ( this.state.PayAll )
         {              
          //  date inférieur à 30j , on doit encaisser la totalité du séjour              
          this.quote.pricingDetails.totalDownPayment = this.quote.pricingDetails.listingInitialPrice +  CumulOptionForSold +  this.quote.pricingDetails.processingFee + this.quote.pricingDetails.mandatoryOptionsCost           
         }    
        
         if (this.state.cancellationInsuranceSubscribed)
          {
           this.quote.pricingDetails.totalDownPayment +=  this.quote.pricingDetails.cancellationInsuranceFee
          }         
                  
        this.props.applicationStore.initializePayment({
          bookingId: this.props.applicationStore.booking.response.bookingNumber,
          amount: this.quote.pricingDetails.totalDownPayment * 100,
          currency: 'EUR'
        }).then(() => {
         
          switch (this.props.applicationStore.payment.response.paymentProvider) {
            case 'payzen':
              this.handleNext()
              this.loadPayzen(this.props.applicationStore.payment.response.paymentParameters.endpoint)
                .then(KRGlue => KRGlue.loadLibrary(this.props.applicationStore.payment.response.paymentParameters.endpoint, this.props.applicationStore.payment.response.paymentParameters.publicKey))
                .then(({ KR }) => KR.setFormConfig({
                  formToken: this.props.applicationStore.payment.response.paymentParameters.formToken,
                  language: navigator.language
                }))
                .then(({ KR }) => KR.addForm('#paymentForm'))
                .then(({ KR, result }) => {
                  KR.setFormConfig({
                    form: {
                      button: {
                        backgroundColor: this.props.theme.palette.secondary.main
                      }
                    }
                  })
                  return KR.showForm(result.formId)
                })
                .then(({ KR }) => KR.onSubmit(evt => {
                  this.setState({
                    paymentStatus: evt.clientAnswer.orderStatus
                  })
                  this.handleNext()
                }))
                .then(({ KR }) => KR.onError(evt => {
                  if (evt.errorCode === 'PSP_099') {
                    this.setState({
                      paymentStatus: evt.errorMessage
                    })
                    this.handleNext()
                  }
                }))
              break

            default:
              break
          }
        })
      }
    })
  }

  handleSubmit = () => {
    this.props.applicationStore.createBooking(this.props.location.state.listingId, this.state.cancellationInsuranceSubscribed, this.listing.discounts.map(p => p.rate)).then(() => {
      if (this.props.applicationStore.booking.state !== 'error') {
        const id = this.props.applicationStore.booking.response.bookingNumber
        Object.keys(this.state.optionsChecked).forEach(o => {
          if (this.state.optionsChecked[o] > 0) {
            this.props.applicationStore.addOption(id, this.options.filter(opt => opt.optionID === o)[0], this.state.optionsChecked[o])
          }
        })
        this.handleNext()
      }
    })
  }

  loadPayzen = endpoint => {
    const cssPromise = new Promise((resolve, reject) => {
      const cssLinkEl = document.createElement('link')
      cssLinkEl.href = `${endpoint}/static/js/krypton-client/V4.0/ext/material-reset.css`
      cssLinkEl.id = 'payzenCSS'
      cssLinkEl.onerror = reject
      cssLinkEl.onload = resolve
      cssLinkEl.rel = 'stylesheet'
      document.body.appendChild(cssLinkEl)
    })

    const jsPromise = new Promise((resolve, reject) => {
      const jsLinkEl = document.createElement('script')
      jsLinkEl.async = true
      jsLinkEl.onerror = reject
      jsLinkEl.onload = resolve
      jsLinkEl.src = `${endpoint}/static/js/krypton-client/V4.0/ext/material.js`
      document.body.appendChild(jsLinkEl)
    })

    return Promise.all([jsPromise, cssPromise])
      .then(() => import('@lyracom/embedded-form-glue'))
      .then(glue => glue.default)
  }

  @action handleCheckboxChange = event => {
    var options = { ...this.state.optionsChecked }
    // options[event.target.name] = !options[event.target.name]
    options[event.target.name] = Number(event.target.value)
    this.setState({
      optionsChecked: options
    })
  }

  @action increaseOption = (event, optID) => {
    var options = { ...this.state.optionsChecked }
    options[optID]++
    this.setState({
      optionsChecked: options
    })
  }

  @action decreaseOption = (event, optID) => {
    var options = { ...this.state.optionsChecked }
    options[optID]--
    if (options[optID] < 0) {
      options[optID] = 0
    }
    this.setState({
      optionsChecked: options
    })
  }

  /* @action handleCheckboxCatChange = event => {
    var options = { ...this.state.optionsChecked }
    Object.keys(this.state.optionsChecked).filter(s => s.includes(event.target.name)).map(c => {
      options[c] = event.target.checked
    })
    this.setState({
      optionsChecked: options
    })
  } */

  handleFacultativeOptionsCollapse = () => {
    this.setState({
      facultativeOptionsOpen: !this.state.facultativeOptionsOpen
    })
  }

  handleMandatoryOptionsCollapse = () => {
    this.setState({
      mandatoryOptionsOpen: !this.state.mandatoryOptionsOpen
    })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    autorun(reaction => {
      if (this.agency.securePaymentAvailable && this.state.paymentChoice === 'option') {
        this.setState({ paymentChoice: 'payment' })
        reaction.dispose()
      }
    })
  }

  CheckAllPay () {
    let nbdays = moment(this.quote.startDate).diff(Date.Now, 'days')  
    if (nbdays < 31  )  
        { this.state.PayAll = true } 
      else 
        { this.state.PayAll = false }
      }


  render () {
    var optionCategories = []
    const mandatoryOptionCategories = []
    if (this.options.length > 0) {
      if (this.options.filter(opt => !opt.mandatory).length > 0) {
        this.options.filter(opt => !opt.mandatory).map(o => {
          if (!optionCategories.includes(o.category)) {
            optionCategories.push(o.category)
          }
          return null
        })
        optionCategories.sort()
        if (optionCategories.includes('Autres')) {
          const index = optionCategories.indexOf('Autres')
          const value = optionCategories[optionCategories.length - 1]
          optionCategories[optionCategories.length - 1] = 'Autres'
          optionCategories[index] = value
        }
      }

      if (this.options.filter(opt => opt.mandatory).length > 0) {
        this.options.filter(opt => opt.mandatory).map(o => {
          if (!mandatoryOptionCategories.includes(o.category)) {
            mandatoryOptionCategories.push(o.category)
          }
          return null
        })
        mandatoryOptionCategories.sort()
        if (mandatoryOptionCategories.includes('Autres')) {
          const index = mandatoryOptionCategories.indexOf('Autres')
          const value = mandatoryOptionCategories[mandatoryOptionCategories.length - 1]
          mandatoryOptionCategories[mandatoryOptionCategories.length - 1] = 'Autres'
          mandatoryOptionCategories[index] = value
        }
      }
    }
    this.rateDiscount()
    this.CheckAllPay()

    return (
      <Grid container justify='center' alignItems='stretch' className={this.props.classes.mainContainer} spacing={isWidthDown('sm', this.props.width) ? 0 : 3}>
        <Grid item sm={11} md={8}>
          <Paper className={this.props.classes.subContainer}>
            <Typography gutterBottom variant='h4'>
              <FormattedMessage
                defaultMessage='Booking'
                id='ReservationEnLigne.Components.Book.Title'
              />
            </Typography>
            <Stepper activeStep={this.state.activeStep} orientation='vertical' style={{ padding: isWidthDown('xs', this.props.width) ? '0' : '12px' }}>
              <Step>
                <StepLabel>
                  <FormattedMessage
                    defaultMessage='Options'
                    id='ReservationEnLigne.Components.Book.Options'
                  />
                </StepLabel>
                <StepContent>
                  {optionCategories.length > 0 &&
                  (
                    <Table padding='dense' style={{ tableLayout: 'fixed' }}>
                      <TableHead>
                        <TableRow style={{ backgroundColor: 'rgba(0,0,0,.1)' }}>
                          <TableCell>
                            {/* <Checkbox /> */}
                          </TableCell>
                          <TableCell>
                            <Typography variant='subtitle2'>
                              <FormattedMessage
                                defaultMessage='Option'
                                id='ReservationEnLigne.Components.Listing.Option'
                              />
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant='subtitle2'>
                              <FormattedMessage
                                defaultMessage='Price'
                                id='ReservationEnLigne.Components.Home.Price'
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {optionCategories.map((cat, n) => {
                          return (
                            <Fragment key={n}>
                              <TableRow style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
                                <TableCell>
                                  {/* <Checkbox/> */}
                                </TableCell>
                                <TableCell colSpan={2}>
                                  <Typography variant='button' color='primary'>
                                    {cat}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              {this.options.filter(opt => opt.category === cat).filter(opt => !opt.mandatory).map((opt, i) => {
                                if (this.state.optionsChecked[opt.optionID] == null) {
                                  var options = { ...this.state.optionsChecked }
                                  options[opt.optionID] = /* false */ 0
                                  this.setState({
                                    optionsChecked: options
                                  })
                                }
                                return (
                                  <TableRow key={i}>
                                    <TableCell className={this.props.classes.optionQuantityContainer}>
                                      <Button variant='contained' size='small' onClick={(e) => window.screen.availWidth < 650 ? this.increaseOption(e, opt.optionID) : this.decreaseOption(e, opt.optionID)} className={this.props.classes.optionQuantityButton}>{window.screen.availWidth < 650 ? '+' : '-'}</Button>
                                      {/* <Checkbox id={opt.optionID} name={opt.optionID} checked={this.state.optionsChecked[opt.optionID]} onChange={this.handleCheckboxChange} /> */}
                                      <MuiTextField id={opt.optionID} name={opt.optionID} variant='outlined' type='number' InputProps={{ inputProps: { min: 0 } }} onChange={this.handleCheckboxChange} value={this.state.optionsChecked[opt.optionID]} style={{ width: window.screen.availWidth < 650 ? '50px' : '75px', margin: window.screen.availWidth < 650 ? 0 : '5px', display: window.screen.availWidth < 650 ? 'block' : 'inline-flex' }} />
                                      <Button variant='contained' size='small' onClick={(e) => window.screen.availWidth < 650 ? this.decreaseOption(e, opt.optionID) : this.increaseOption(e, opt.optionID)} className={this.props.classes.optionQuantityButton}>{window.screen.availWidth < 650 ? '-' : '+'}</Button>
                                    </TableCell>
                                    <TableCell>{opt.name}</TableCell>
                                    <TableCell>
                                      {/* eslint-disable-next-line react/style-prop-object */}
                                      <FormattedNumber style='currency' currency='EUR' value={opt.unitPrice} />
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </Fragment>
                          )
                        })}
                      </TableBody>
                    </Table>
                  )}
                  <Button
                    variant='contained'
                    color='primary'
                    className={this.props.classes.button}
                    onClick={this.handleNext}
                  >
                    <FormattedMessage
                      defaultMessage='Next'
                      id='ReservationEnLigne.Components.Book.Next'
                    />
                  </Button>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <FormattedMessage
                    defaultMessage='Contact details'
                    id='ReservationEnLigne.Components.Book.ContactDetails'
                  />
                </StepLabel>
                <StepContent>
                  <Formik
                    initialValues={this.props.applicationStore.tenantDetails}
                    onSubmit={(values, { setSubmitting }) => {
                      this.props.applicationStore.setTenantDetails(values)
                      setSubmitting(false)
                      this.handleNext()
                    }}
                    validationSchema={
                      yup.object({
                        title: yup.string(),
                        lname: yup.string().required(this.props.intl.formatMessage(this.messages.requiredField)),
                        fname: yup.string().required(this.props.intl.formatMessage(this.messages.requiredField)),
                        address1: yup.string(),
                        address2: yup.string(),
                        postal: yup.string(),
                        city: yup.string(),
                        country: yup.string(),
                        email: yup.string().email().required(this.props.intl.formatMessage(this.messages.requiredField))
                      }).shape({
                        homephone: yup.string().when(['workphone', 'mobile'], {
                          is: (workphone, mobile) => !workphone && !mobile,
                          then: yup.string().required(this.props.intl.formatMessage(this.messages.atLeastOnePhoneNumber))
                        }),
                        workphone: yup.string().when(['homephone', 'mobile'], {
                          is: (homephone, mobile) => !homephone && !mobile,
                          then: yup.string().required(this.props.intl.formatMessage(this.messages.atLeastOnePhoneNumber))
                        }),
                        mobile: yup.string().when(['homephone', 'workphone'], {
                          is: (homephone, workphone) => !homephone && !workphone,
                          then: yup.string().required(this.props.intl.formatMessage(this.messages.atLeastOnePhoneNumber))
                        })
                      }, [['homephone', 'workphone'], ['homephone', 'mobile'], ['workphone', 'mobile']])
                    }
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item sm={2} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing honorific-prefix'
                              label={
                                <FormattedMessage
                                  defaultMessage='Title'
                                  id='ReservationEnLigne.Components.Book.TenantTitle'
                                />
                              }
                              name='title'
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={5} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing family-name'
                              name='lname'
                              label={
                                <FormattedMessage
                                  defaultMessage='Last name'
                                  id='ReservationEnLigne.Components.Book.TenantLastName'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={5} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing given-name'
                              name='fname'
                              label={
                                <FormattedMessage
                                  defaultMessage='First name'
                                  id='ReservationEnLigne.Components.Book.TenantFirstName'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing address-line1'
                              name='address1'
                              label={
                                <FormattedMessage
                                  defaultMessage='Address'
                                  id='ReservationEnLigne.Components.Book.TenantAddress1'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing address-line2'
                              name='address2'
                              label={
                                <FormattedMessage
                                  defaultMessage='Address (continued)'
                                  id='ReservationEnLigne.Components.Book.TenantAddress2'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing postal-code'
                              name='postal'
                              label={
                                <FormattedMessage
                                  defaultMessage='Post code'
                                  id='ReservationEnLigne.Components.Book.TenantPostCode'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={9} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing address-level2'
                              name='city'
                              label={
                                <FormattedMessage
                                  defaultMessage='City'
                                  id='ReservationEnLigne.Components.Book.TenantCity'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing country-name'
                              name='country'
                              label={
                                <FormattedMessage
                                  defaultMessage='State / Country'
                                  id='ReservationEnLigne.Components.Book.TenantCountry'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing home email'
                              name='email'
                              type='email'
                              label={
                                <FormattedMessage
                                  defaultMessage='Email address'
                                  id='ReservationEnLigne.Components.Book.TenantEmail'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing mobile tel'
                              name='mobile'
                              type='tel'
                              label={
                                <FormattedMessage
                                  defaultMessage='Mobile phone'
                                  id='ReservationEnLigne.Components.Book.TenantMobilePhone'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing home tel'
                              name='homephone'
                              type='tel'
                              label={
                                <FormattedMessage
                                  defaultMessage='Home phone'
                                  id='ReservationEnLigne.Components.Book.TenantHomePhone'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Field
                              fullWidth
                              autoComplete='billing work tel'
                              name='workphone'
                              type='tel'
                              label={
                                <FormattedMessage
                                  defaultMessage='Work phone'
                                  id='ReservationEnLigne.Components.Book.TenantWorkPhone'
                                />
                              }
                              margin='dense'
                              component={TextField}
                            />
                          </Grid>
                        </Grid>
                        <div className={this.props.classes.actionsContainer}>
                          <div>
                            <Button
                              variant='contained'
                              color='secondary'
                              className={this.props.classes.button}
                              onClick={this.handleBack}
                            >
                              <FormattedMessage
                                defaultMessage='Back'
                                id='ReservationEnLigne.Components.Book.Back'
                              />
                            </Button>
                            <Button
                              variant='contained'
                              color='primary'
                              className={this.props.classes.button}
                              disabled={isSubmitting}
                              type='submit'
                            >
                              <FormattedMessage
                                defaultMessage='Next'
                                id='ReservationEnLigne.Components.Book.Next'
                              />
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  <FormattedMessage
                    defaultMessage='Summary'
                    id='ReservationEnLigne.Components.Book.Summary'
                  />
                </StepLabel>
                <StepContent>
                  <Typography variant='subtitle2'>
                    <FormattedMessage
                      defaultMessage='Billing address'
                      id='ReservationEnLigne.Components.Book.BillingAddress'
                    />
                  </Typography>
                  <Typography>
                    {this.props.applicationStore.tenantDetails.title} {this.props.applicationStore.tenantDetails.lname} {this.props.applicationStore.tenantDetails.fname}
                  </Typography>
                  <Typography>
                    {this.props.applicationStore.tenantDetails.address1}
                  </Typography>
                  <Typography>
                    {this.props.applicationStore.tenantDetails.address2}
                  </Typography>
                  <Typography>
                    {this.props.applicationStore.tenantDetails.postal} {this.props.applicationStore.tenantDetails.city}
                  </Typography>
                  <Typography>
                    {this.props.applicationStore.tenantDetails.country}
                  </Typography>
                  <Typography>
                    {this.props.applicationStore.tenantDetails.email}
                  </Typography>
                  {this.props.applicationStore.tenantDetails.mobile && (
                    <Typography>
                      {parsePhoneNumberFromString(this.props.applicationStore.tenantDetails.mobile, 'FR').format('INTERNATIONAL')}
                    </Typography>
                  )}
                  {this.props.applicationStore.tenantDetails.homephone && (
                    <Typography>
                      {parsePhoneNumberFromString(this.props.applicationStore.tenantDetails.homephone, 'FR').format('INTERNATIONAL')}
                    </Typography>
                  )}
                  {this.props.applicationStore.tenantDetails.workphone && (
                    <Typography>
                      {parsePhoneNumberFromString(this.props.applicationStore.tenantDetails.workphone, 'FR').format('INTERNATIONAL')}
                    </Typography>
                  )}
                  <Divider style={{ margin: '1rem 0' }} />
                  <Typography variant='subtitle2'>
                    <FormattedMessage
                      defaultMessage='Financial summary'
                      id='ReservationEnLigne.Components.Book.FinancialSummary'
                    />
                  </Typography>
                  <Table padding='dense' style={{ tableLayout: 'fixed' }}>
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <FormattedMessage
                            defaultMessage='Base price'
                            id='ReservationEnLigne.Components.Listing.ListingInitialPrice'
                          />
                        </TableCell>
                        <TableCell numeric>
                          {/* Pas d arrondi => comme avant */}
                          {this.agency.roundingRule === 0 && (
                            // eslint-disable-next-line react/style-prop-object
                            <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.listingInitialPrice} />
                          )}
                          {/* Arrondi à la dizaine de centimes sup */}
                          {this.agency.roundingRule === 1 && (
                            // eslint-disable-next-line react/style-prop-object
                            <FormattedNumber style='currency' currency='EUR' value={(Math.ceil(this.quote.pricingDetails.listingInitialPrice * 10)) / 10} />
                          )}
                          {/* Arrondis à l'euro sup */}
                          {this.agency.roundingRule === 2 && (
                            // eslint-disable-next-line react/style-prop-object
                            <FormattedNumber style='currency' currency='EUR' value={Math.ceil(this.quote.pricingDetails.listingInitialPrice)} />
                          )}
                          {/* Arrondis à la dizaine d'euros sup */}
                          {this.agency.roundingRule === 3 && (
                            // eslint-disable-next-line react/style-prop-object
                            <FormattedNumber style='currency' currency='EUR' value={(Math.ceil(this.quote.pricingDetails.listingInitialPrice / 10)) * 10} />
                          )}
                        </TableCell>
                      </TableRow>
                      {this.quote.pricingDetails.listingDiscount < 0 && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <FormattedMessage
                              defaultMessage='Discount'
                              id='ReservationEnLigne.Components.Listing.ListingDiscount'
                            />
                            &nbsp;(
                            {this.quote.pricingDetails.earlyLast > 0 && (
                              this.quote.pricingDetails.earlyLast === 1 ? (
                                <FormattedMessage
                                  defaultMessage='Early Booking'
                                  id='ReservationEnLigne.Components.Listing.Early'
                                />
                              ) : (
                                <FormattedMessage
                                  defaultMessage='Last Minute'
                                  id='ReservationEnLigne.Components.Listing.LastMinute'
                                />
                              )
                            )}
                            )
                          </TableCell>
                          <TableCell numeric style={{ color: this.props.theme.palette.secondary.main }}>
                            {/* eslint-disable-next-line react/style-prop-object */}
                            <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.listingDiscount} />
                          </TableCell>
                        </TableRow>
                      )}
                      {this.quote.pricingDetails.cleaningFee > 0 && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <FormattedMessage
                              defaultMessage='Cleaning fee'
                              id='ReservationEnLigne.Components.Listing.CleaningFee'
                            />
                          </TableCell>
                          <TableCell numeric>
                            {/* eslint-disable-next-line react/style-prop-object */}
                            <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.cleaningFee} />
                          </TableCell>
                        </TableRow>
                      )}
                      {this.quote.pricingDetails.processingFee > 0 && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <FormattedMessage
                              defaultMessage='Processing fee'
                              id='ReservationEnLigne.Components.Listing.ProcessingFee'
                            />
                          </TableCell>
                          <TableCell numeric>
                            {/* eslint-disable-next-line react/style-prop-object */}
                            <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.processingFee} />
                          </TableCell>
                        </TableRow>
                      )}
                      {this.quote.pricingDetails.mandatoryOptionsCost > 0 && (
                        <TableRow onClick={this.handleMandatoryOptionsCollapse} style={{ cursor: 'pointer' }}>
                          <TableCell colSpan={3}>
                            <FormattedMessage
                              defaultMessage='Mandatory options'
                              id='ReservationEnLigne.Components.Listing.MandatoryOptionsCost'
                            />
                            {this.state.mandatoryOptionsOpen ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />)}
                          </TableCell>
                          <TableCell numeric>
                            {/* eslint-disable-next-line react/style-prop-object */}
                            <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.mandatoryOptionsCost} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    <TableBody style={{
                      visibility: this.state.mandatoryOptionsOpen ? 'visible' : 'collapse',
                      opacity: this.state.mandatoryOptionsOpen ? 1 : 0,
                      transition: 'visibility 300ms, opacity 300ms'
                    }}
                    >
                      {mandatoryOptionCategories.length > 0
                        ? mandatoryOptionCategories.map((cat, n) =>
                          this.options.filter(opt => opt.category === cat && opt.mandatory).length > 0
                            ? this.options.filter(opt => opt.category === cat && opt.mandatory).map((o, n) => {
                              return (
                                <TableRow key={n}>
                                  <TableCell colSpan={1} style={{ fontSize: '11px' }}>
                                    {n === 0 ? cat : ''}
                                  </TableCell>
                                  <TableCell colSpan={2} style={{ fontSize: '11px' }}>
                                    {o.name}
                                  </TableCell>
                                  <TableCell align='right' style={{ fontSize: '11px' }}>
                                    {/* eslint-disable-next-line react/style-prop-object */}
                                    <FormattedNumber style='currency' currency='EUR' value={o.unitPrice} />
                                  </TableCell>
                                </TableRow>
                              )
                            }) : ''
                        ) : ''}
                    </TableBody>
                    <TableBody>
                      {Object.entries(this.state.optionsChecked).filter(o => o[1] > 0).length > 0 &&
                        (
                          <TableRow onClick={this.handleFacultativeOptionsCollapse} style={{ cursor: 'pointer' }}>
                            <TableCell colSpan={3}>
                              <FormattedMessage
                                defaultMessage='Options'
                                id='ReservationEnLigne.Components.Listing.FacultativeOptions'
                              />
                              {this.state.facultativeOptionsOpen ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />)}
                            </TableCell>
                            <TableCell numeric>
                              <FormattedNumber
                                // eslint-disable-next-line react/style-prop-object
                                style='currency' currency='EUR' value={Object.entries(this.state.optionsChecked).filter(o => o[1] > 0).reduce((acc, val) => {
                                  acc += this.options.filter(opt => opt.optionID === val[0])[0].unitPrice * val[1]
                                  return acc
                                }, 0)}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                    </TableBody>
                    <TableBody style={{
                      visibility: this.state.facultativeOptionsOpen ? 'visible' : 'collapse',
                      opacity: this.state.facultativeOptionsOpen ? 1 : 0,
                      transition: 'visibility 300ms, opacity 300ms'
                    }}
                    >
                      {Object.entries(this.state.optionsChecked).filter(o => o[1] > 0 > 0).length > 0 && Object.entries(this.state.optionsChecked).filter(o => o[1] > 0).map((a, n) => {
                        const opt = this.options.filter(opt => opt.optionID === a[0])[0]
                        return (
                          <TableRow key={n}>
                            <TableCell style={{ fontSize: '11px' }}>
                              {opt.category}
                            </TableCell>
                            <TableCell style={{ fontSize: '11px' }}>
                              {opt.name + ' (x' + a[1] + ')'}
                            </TableCell>
                            <TableCell style={{ fontSize: '11px', textAlign: 'right' }}>
                              {/* eslint-disable-next-line react/style-prop-object */}
                              <FormattedNumber style='currency' currency='EUR' value={opt.unitPrice} /> x {a[1]}
                            </TableCell>
                            <TableCell numeric style={{ fontSize: '11px' }}>
                              {/* eslint-disable-next-line react/style-prop-object */}
                              <FormattedNumber style='currency' currency='EUR' value={opt.unitPrice * a[1]} />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                    <TableBody>
                      {this.quote.pricingDetails.cancellationInsuranceFee > 0 && this.state.cancellationInsuranceSubscribed && (
                        <TableRow style={{ fontStyle: 'italic' }}>
                          <TableCell colSpan={3}>
                            <FormattedMessage
                              defaultMessage='Cancellation insurance (optional)'
                              id='ReservationEnLigne.Components.Listing.CancellationInsuranceFee'
                            />
                          </TableCell>
                          <TableCell numeric>
                            {/* eslint-disable-next-line react/style-prop-object */}
                            <FormattedNumber style='currency' currency='EUR' value={this.quote.pricingDetails.cancellationInsuranceFee} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <FormattedMessage
                            defaultMessage='Total price'
                            id='ReservationEnLigne.Components.Book.TotalPrice'
                          />
                        </TableCell>
                        <TableCell numeric>
                          <FormattedNumber
                            style='currency' // eslint-disable-line react/style-prop-object
                            currency='EUR'
                            value={
                              this.quote.totalPrice +
                              (this.state.cancellationInsuranceSubscribed ? this.quote.pricingDetails.cancellationInsuranceFee : 0) +
                              (Object.entries(this.state.optionsChecked).filter(o => o[1] > 0).length > 0 ? Object.entries(this.state.optionsChecked).filter(o => o[1] > 0).reduce((acc, val) => {
                                acc += this.options.filter(opt => opt.optionID === val[0])[0].unitPrice * val[1]
                                return acc
                              }, 0) : 0)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <Divider style={{ margin: '1rem 0' }} />
                  {this.agency.securePaymentAvailable ? (
                    <RadioGroup
                      aria-label='Payment choice'
                      name='payment'
                      value={this.state.paymentChoice}
                      onChange={evt => this.setState({ paymentChoice: evt.target.value })}
                    >
                       {this.state.PayAll === true ? (
                      <FormControlLabel
                        value='payment' control={<Radio />} label={
                          <FormattedMessage
                            defaultMessage='I pay the entire rental and services.'
                            id='ReservationEnLigne.Components.Book.RadioPaymentLabel31j'                            
                          />
                        }
                      />
                       ) : (
                      <FormControlLabel
                        value='payment' control={<Radio />} label={                                          
                          <FormattedMessage
                            defaultMessage='I want an instant booking and will pay my down payment right now.'
                            id='ReservationEnLigne.Components.Book.RadioPaymentLabel'
                          />                         
                        }
                      /> )
                      }
                      <FormControlLabel
                        value='option' control={<Radio />} label={
                          <FormattedMessage
                            defaultMessage='I want to create an option instead to temporarily lock the listing and pay later or through another means. It will be valid for {optionDuration} days.'
                            id='ReservationEnLigne.Components.Book.RadioOptionLabel'
                            values={{
                              optionDuration: 5
                            }}
                          />
                        }
                      />                      
                      
                    </RadioGroup>
                  ) : (
                    <Typography>
                      <FormattedMessage
                        defaultMessage='An option will be created, locking the listing for you during {optionDuration} days. We will be waiting for your down payment and will contact you quickly about this.'
                        id='ReservationEnLigne.Components.Book.SubmitMessage'
                        values={{
                          optionDuration: 5
                        }}
                      />
                    </Typography>
                  )}
                  <FormGroup>
                    {this.quote.pricingDetails.cancellationInsuranceFee > 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.cancellationInsuranceSubscribed}
                            onChange={evt => this.setState({ cancellationInsuranceSubscribed: evt.target.checked })}
                            value='cancellationInsuranceSubscribed'
                          />
                        }
                        label={
                          <FormattedMessage
                            defaultMessage='I want to subscribe to the cancellation insurance'
                            id='ReservationEnLigne.Components.Book.SubscribeToInsurance'
                          />
                        }
                      />
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.termsAccepted}
                          onChange={evt => this.setState({ termsAccepted: evt.target.checked })}
                          value='termsAccepted'
                        />
                      }
                      label={
                        <>
                          <FormattedMessage
                            defaultMessage='I have read and understood the general terms and conditions'
                            id='ReservationEnLigne.Components.Book.IHaveReadTerms'
                          />
                          {' '}
                          <a href={`https://www.heliosconnect.fr/clients/${this.listing.agencyId}/cgl.pdf`} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage
                              defaultMessage='(visible here)'
                              id='ReservationEnLigne.Components.Book.VisibleHere'
                            />
                          </a>
                        </>
                      }
                    />
                  </FormGroup>
                  <div className={this.props.classes.actionsContainer}>
                    <div>
                      <Button
                        onClick={this.handleBack}
                        className={this.props.classes.button}
                      >
                        <FormattedMessage
                          defaultMessage='Back'
                          id='ReservationEnLigne.Components.Book.Back'
                        />
                      </Button>
                      <Button
                        disabled={!this.state.termsAccepted || this.props.applicationStore.booking.state !== 'new'}
                        variant='contained'
                        color='primary'
                        onClick={this.state.paymentChoice === 'payment' ? this.handlePayment : this.handleSubmit}
                        className={this.props.classes.button}
                      >
                        {this.state.paymentChoice === 'payment' ? (
                          <FormattedMessage
                            defaultMessage='Next'
                            id='ReservationEnLigne.Components.Book.Next'
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage='Submit'
                            id='ReservationEnLigne.Components.Book.Submit'
                          />
                        )}
                      </Button>
                    </div>
                  </div>
                  {this.props.applicationStore.booking.state === 'error' && (
                    <Typography color='error'>
                      Une erreur s'est produite. Veuillez réessayer ultérieurement. {this.props.applicationStore.booking.response.error}
                    </Typography>
                  )}
                </StepContent>
              </Step>
              {this.state.paymentChoice === 'payment' && (
                <Step>
                  <StepLabel>
                    <FormattedMessage
                      defaultMessage='Payment'
                      id='ReservationEnLigne.Components.Book.Payment'
                    />
                  </StepLabel>
                  <StepContent>
                    <div id='paymentForm' />
                  </StepContent>
                </Step>
              )}
              <Step>
                <StepLabel>
                  <FormattedMessage
                    defaultMessage='Confirmation'
                    id='ReservationEnLigne.Components.Book.Confirmation'
                  />
                </StepLabel>
                <StepContent>
                  <Typography>
                    {this.state.paymentChoice === 'payment' ? (
                      this.state.paymentStatus === 'PAID'  ? (
                        this.state.PayAll === false ? (
                        <FormattedMessage
                          defaultMessage='Your down payment has been accepted, and we registered your instant booking! Its number is {bookingNumber}.'
                          id='ReservationEnLigne.Components.Book.ValidatedAndPaid'
                          values={{
                            bookingNumber: this.props.applicationStore.booking.response.bookingNumber
                          }}
                        />                        
                        ) : (
                          <FormattedMessage
                          defaultMessage='Your payment has been accepted, and we registered your instant booking! Its number is {bookingNumber}.'
                          id='ReservationEnLigne.Components.Book.ValidatedAndPaid31J'
                          values={{
                            bookingNumber: this.props.applicationStore.booking.response.bookingNumber
                          }}
                        />
                        )
                      ) : (
                        <FormattedMessage
                          defaultMessage='Your down payment has been refused. We placed a booking option for you, and we will contact you to sort this situation out. Its number is {bookingNumber}.'
                          id='ReservationEnLigne.Components.Book.ValidatedButDenied'
                          values={{
                            bookingNumber: this.props.applicationStore.booking.response.bookingNumber
                          }}
                        />
                      )
                    ) : (
                      <FormattedMessage
                        defaultMessage='Your booking option has been registered! Its number is {bookingNumber}.'
                        id='ReservationEnLigne.Components.Book.Validated'
                        values={{
                          bookingNumber: this.props.applicationStore.booking.response.bookingNumber
                        }}
                      />
                    )}
                  </Typography>
                </StepContent>
              </Step>
            </Stepper>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default BookPage
