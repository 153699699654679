// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Packages
import {
  Button,
  Grid,
  Typography
} from '@material-ui/core'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@material-ui/icons'

class Spinner extends Component {
  static propTypes = {
    maxVal: PropTypes.number,
    minVal: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired
  }

  static defaultProps = {
    maxVal: 16,
    minVal: 0
  }

  render () {
    return (
      <Grid container>
        <Grid item xs>
          {this.props.children}
        </Grid>
        <Grid item xs container alignItems='center' spacing={1}>
          <Grid item xs>
            <Button variant='fab' mini color='primary' aria-label='Add' disabled={this.props.value <= this.props.minVal} onClick={this.props.onUpdate(-1)}>
              <KeyboardArrowLeft />
            </Button>
          </Grid>
          <Grid item xs>
            <Typography variant='subtitle1' color='inherit' align='center'>
              {this.props.value}
            </Typography>
          </Grid>
          <Grid item xs>
            <Button variant='fab' mini color='primary' aria-label='Add' disabled={this.props.value >= this.props.maxVal} onClick={this.props.onUpdate(1)}>
              <KeyboardArrowRight />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Spinner
