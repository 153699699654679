export const agencyMarker = fillColor => `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm2.178 2.373c.75 0 1.351.6 1.351 1.35 0 .75-.601 1.35-1.351 1.35-.09 0-.18-.015-.254-.03-.105.12-.225.27-.225.27a.139.139 0 0 1-.12.06h-.45v.45c0 .09-.06.15-.15.15h-.452v.45c0 .09-.06.15-.15.15h-.6v.45c0 .09-.06.15-.15.15h-.75a.147.147 0 0 1-.15-.12l-.149-.75c0-.045.015-.105.045-.135l2.234-2.19a1.33 1.33 0 0 1-.029-.255c0-.75.6-1.35 1.35-1.35zm.15.645a.601.601 0 0 0 0 1.2c.33 0 .602-.27.602-.6 0-.33-.272-.6-.602-.6zM9.977 9.342a.84.84 0 0 1 .45.16c.226.165.541.271.856.271h.584c.435 0 .84.21 1.08.555.09.135.062.346.032.346h-2.252c-.466 0-.75.224-.75.6 0 .09.06.15.15.15s.15-.06.15-.15c0-.076 0-.301.45-.301h2.252c.03 0 .06-.016.105-.016.105-.09.254-.12.389-.135.225-.015.42-.059.54-.119l1.292-.795c.675-.36.929-.344 1.199.016.12.18.09.45-.09.6l-2.266 1.74a.88.88 0 0 1-.496.254.874.874 0 0 1-.209.03l-1.95.3c-.3.045-.615.076-.915.076H8.627v.449h-1.05v-3.9h1.05v.33c.315-.203.866-.497 1.35-.461zm-2.1.431v3.3h.45v-3.3h-.45z" fill="${fillColor}"/>
</svg>
`

export const listingMarker = fillColor => `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path d="M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm-1 7v3h-2.5v-4h-1.5l5 -4.5 5 4.5h-1.5v4h-2.5v-3h-2z" fill="${fillColor}"/>
</svg>
`
