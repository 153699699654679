import { action, flow, observable } from 'mobx'

export class ApplicationMockStore {
  @observable agencies = {}
  @observable mainAgencyId = ''
  @observable destinations = []
  @observable listingTypes = []
  @observable listings = {
    response: {},
    state: 'new'
  }

  @observable guestCount = {
    adults: 1,
    children: 0,
    babies: 0
  }

  @observable travelDates = {
    startDate: null,
    endDate: null
  }

  @observable quote = {
    response: null,
    state: 'new'
  }

  @observable listingFilters = {
    destination: 'any',
    enabled: false
  }

  @action getAgenciesFromSubdomain = flow(function * (subdomain) {
    this.agencies = {
      '061810001': {
        agencyId: '061810001',
        name: 'AGENCE TEST',
        address1: '1, Rond Point de Flotis',
        address2: '',
        address3: 'BP3322254',
        postCode: '31240',
        city: 'SAINT JEAN',
        emailAddress: 'maj@heliosconnect.fr',
        phone: '04.89.86.16.10',
        mainColor: '#009688',
        accentColor: '#3F51B5',
        minArrivalTime: '14:00:00',
        maxDepartureTime: '10:00:00'
      },
      218311099: {
        agencyId: '218311099',
        name: 'MP CONCEPT',
        address1: '334 Rue du Luxembourg',
        address2: 'Za Les Playes Jean Monnet Nord',
        address3: '',
        postCode: '83500',
        city: 'LA SEYNE SUR MER',
        emailAddress: 'jcpoirel@mp-concept.fr',
        phone: '+ 33.(0)4.89.8',
        mainColor: '#3F51B5',
        accentColor: '#00E5FF',
        minArrivalTime: '15:00:00',
        maxDepartureTime: '10:00:00'
      }
    }
    this.mainAgencyId = '061810001'
    yield
  })

  @action getDestinationsFromAgencies = flow(function * () {
    this.destinations = [
      'SAINT JEAN',
      'PAU',
      'OLLIOULES',
      'LATTES'
    ]
    yield
  })

  @action getListingsFromAgencies = flow(function * () {
    this.listings = {
      response: {
        12: {
          id: 12,
          ref: 'A203',
          catchphrase:
          `
          Studio 30m², 4 personnes, agréable et lumineux, balcon Sud-Est avec superbe vue.
          Draps en sus pour 8€ /personne
          `,
          indPref: 99,
          photos: [
            {
              id: '12a',
              caption: 'Caption 1',
              uri: 'https://www.heliosconnect.fr/clients/061810001/photos/12a.jpg'
            }
          ]
        }
      },
      state: 'done'
    }
    yield
  })

  handleGuestCountArrowClick = counter => step => action(() => {
    this.guestCount[counter] += step
  })

  @action handleTravelDatesChange = ({ startDate, endDate }) => {
    this.travelDates = {
      startDate,
      endDate
    }
  }

  setBooleanFilter = filterName => action(`set${filterName.charAt(0).toUpperCase() + filterName.slice(1)}`, evt => {
    this.listingFilters[filterName] = evt.target.checked
  })
}
