// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Packages
import {
  Button,
  Card,
  CardActions,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core'
import {
  NotListedLocation
} from '@material-ui/icons'
import { computed } from 'mobx'
import { observer, inject } from 'mobx-react'
import ContentLoader from 'react-content-loader'

// Internal
import ListingCard from './ListingCard'

const styles = theme => ({
  noResultIcon: {
    display: 'block',
    fontSize: 72,
    margin: 'auto',
    paddingBottom: theme.spacing(2)
  }
})

@withStyles(styles, { withTheme: true })
@inject('applicationStore')
@observer
class ListingGrid extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filter: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired
  }

  static defaultProps = {
    filter: x => true
  }

  @computed get filteredListings () {
    return Object.values(this.props.applicationStore.listings.response).filter(this.props.filter).sort((a, b) => a.ref.localeCompare(b.ref, 'fr', { ignorePunctuation: true })).sort((a, b) => b.indPref - a.indPref)
  }

  render () {
    const store = this.props.applicationStore

    let listings = []

    if (store.listings.state === 'pending') {
      listings = [...Array(6).keys()].map(id => (
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={id}>
          <Card style={{ display: 'flex', flexFlow: 'column', minHeight: '100%' }}>
            <ContentLoader
              height={329}
              width={368}
              speed={2}
              backgroundColor={this.props.theme.palette.primary.light}
              foregroundColor={this.props.theme.palette.primary.dark}
              style={{ flex: 1 }}
            >
              <rect x='0' y='0' rx='3' ry='3' width='368' height='207' />
              <rect x='22' y='220' rx='3' ry='3' width='162' height='30' />
              <rect x='22' y='256' rx='3' ry='3' width='324' height='16' />
              <rect x='22' y='276' rx='3' ry='3' width='324' height='16' />
              <rect x='22' y='296' rx='3' ry='3' width='324' height='16' />
            </ContentLoader>
            <CardActions>
              <Button size='small' color='primary'>
                ████████
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))
    } else if (store.listings.state === 'error') {
      listings = (<p>Error :(</p>)
    } else if (store.listings.state === 'done') {
      listings = this.filteredListings.length > 0
        ? this.filteredListings.map(listing => (
          <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={listing.listingId}>
            <ListingCard listing={listing} />
          </Grid>
        ))
        : (
          <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
            <NotListedLocation classes={{ root: this.props.classes.noResultIcon }} />
            <Typography variant='h5' align='center' style={{ fontStyle: 'italic' }}>
              Pas de résultat
            </Typography>
          </Grid>
        )
    }

    return (
      <Grid container justify={this.filteredListings.length === 0 ? 'center' : 'flex-start'} spacing={3} style={{ paddingTop: '32px' }}>
        {listings}
      </Grid>
    )
  }
}

export default ListingGrid
