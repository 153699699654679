import { action, configure, flow, observable } from 'mobx'
import moment from 'moment'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import SwaggerClient from 'swagger-client'
import { date } from 'yup'

export class ApplicationStore {
  api = new SwaggerClient(process.env.REACT_APP_API_ENDPOINT || 'https://api.heliosconnect.fr/v0/swagger.json')

  @observable agencies = {}
  @observable mainAgencyId = ''
  @observable mainAgencyBackdrop = null
  @observable mainAgencyLogo = null
  @observable destinations = []
  @observable listingTypes = []
  @observable listings = {
    response: {},
    state: 'new'
  }

  @observable guestCount = {
    adults: 1,
    children: 0,
    babies: 0
  }

  @observable tenantDetails = {
    title: '',
    lname: '',
    fname: '',
    address1: '',
    address2: '',
    postal: '',
    city: '',
    country: '',
    homephone: '',
    workphone: '',
    mobile: '',
    email: ''
  }

  @observable travelDates = {
    startDate: null,
    endDate: null
  }

  @observable quotes = {}
  @observable listingFilters = {
    destination: '',
    enabled: false,
    listingType: '',
    petsAccepted: false,
    priceRange: [],
    surfaceAreaRange: [],
    wifiIncluded: false
  }

  @observable subdomain = ''
  @observable booking = {
    response: {},
    state: 'new'
  }

  @observable priceRangeDomain = [0, 100]
  @observable surfaceAreaRangeDomain = [0, 100]
  @observable options = {};
  @observable payment = {
    response: {},
    state: 'new'
  }

  @observable addedOptions = [];

  constructor () {
    configure({ enforceActions: 'observed' })
    window.api = this.api
  }

  @action createBooking = flow(function * (listingId, cancellationInsuranceSubscribed, rateDiscount) {
    this.booking.state = 'pending'
    this.quotes[listingId].pricingDetails.DiscountRate = rateDiscount > 0 ? rateDiscount[0] : 0
    try {
      const bookingResponse = yield this.api.then(client => client.apis.bookings.createBooking({}, {
        requestBody: {
          listingId,
          agencyId: this.listings.response[listingId].agencyId,
          tenant: {
            tenantId: "-1",
            agencyId: this.listings.response[listingId].agencyId,
            title: this.tenantDetails.title,
            lastName: this.tenantDetails.lname,
            firstName: this.tenantDetails.fname,
            addressLine1: this.tenantDetails.address1,
            addressLine2: this.tenantDetails.address2,
            postCode: this.tenantDetails.postal,
            city: this.tenantDetails.city,
            country: this.tenantDetails.country,
            emailAddress: this.tenantDetails.email,
            mobilePhone: this.tenantDetails.mobile !== '' ? parsePhoneNumberFromString(this.tenantDetails.mobile, 'FR').format('E.164') : '',
            homePhone: this.tenantDetails.homephone !== '' ? parsePhoneNumberFromString(this.tenantDetails.homephone, 'FR').format('E.164') : '',
            workPhone: this.tenantDetails.workphone !== '' ? parsePhoneNumberFromString(this.tenantDetails.workphone, 'FR').format('E.164') : ''
          },
          quote: this.quotes[listingId],
          cancellationInsuranceSubscribed,
          origin: 'ReservationEnLigne',
          displayedOrigin: this.subdomain,
          sendEmailToTenant: true
        }
      }))
      this.addedOptions = []
      this.booking = {
        response: bookingResponse.body,
        state: bookingResponse.body.error ? 'error' : 'done'
      }
    } catch {
      this.booking = {
        response: {
          error: 'An unknown error occured'
        },
        state: 'error'
      }
    }
  })

  @action disableListingFilters = () => {
    this.listingFilters.enabled = false
    this.listingFilters.destination = ''
  }

  @action enableListingFilters = () => {
    this.listingFilters.enabled = true
  }

  @action getAgenciesFromSubdomain = flow(function * (subdomain) {
    this.subdomain = process.env.REACT_APP_SUBDOMAIN_OVERRIDE || subdomain
    const agencies = yield this.api.then(client => client.apis.agency.getAgenciesFromSubdomain({ subdomain: this.subdomain }))
    this.agencies = agencies.body.reduce((acc, val) => {
      acc[val.agencyId] = val
      return acc
    }, {})
    this.mainAgencyId = agencies.body[0].agencyId
  })

  @action getDestinationsFromAgencies = flow(function * () {
    const destinations = yield Promise.all(Object.keys(this.agencies).map(agencyId => this.api.then(client => client.apis.agency.getDestinationsFromAgency({ agencyId }))))
    this.destinations = Array.from(new Set(Array.prototype.concat.apply([], destinations.map(dest => dest.body)))).sort()
  })

  @action getListingsFromAgencies = flow(function * () {
    this.listings.state = 'pending'
    try {
      const listings = yield Promise.all(Object.keys(this.agencies).map(agencyId => this.api.then(client => client.apis.listings.getListingsFromAgency({ agencyId }))))
      this.listings = {
        response: Array.prototype.concat.apply([], listings.map(listing => listing.body)).reduce((acc, val) => {
          val.prices.sort((a, b) => moment(a.startDate).diff(b.startDate, 'days'))
          acc[val.listingId] = val
          return acc
        }, {}),
        state: 'done'
      }
      for (const listingId of Object.keys(this.listings.response)) {
        this.quotes[listingId] = {
          state: 'new'
        }
        this.options[listingId] = []
      }

      const defaultPrices = Object.values(this.listings.response).map(l => Math.min(...l.prices.map(p => p.weeklyPrice))).filter(p => p !== Infinity)
      this.priceRangeDomain = [Math.floor(Math.min(...defaultPrices)), Math.ceil(Math.max(...defaultPrices))]
      this.listingFilters.priceRange = [...this.priceRangeDomain]

      const surfaceAreas = Object.values(this.listings.response).map(l => l.surfaceArea)
      this.surfaceAreaRangeDomain = [Math.floor(Math.min(...surfaceAreas)), Math.ceil(Math.max(...surfaceAreas))]
      this.listingFilters.surfaceAreaRange = [...this.surfaceAreaRangeDomain]
    } catch (err) {
      this.listings.state = 'error'
    }
  })

  @action getOptions = flow(function * (agencyId, listingId) {
    const options = yield this.api.then(client => client.apis.listings.getOptions({ agencyId, listingId, startDate: this.travelDates.startDate.toJSON(), endDate: this.travelDates.endDate.toJSON() }))
    this.options[listingId] = options.body
  })

  @action getListingTypesFromAgencies = flow(function * () {
    const listingTypes = yield Promise.all(Object.keys(this.agencies).map(agencyId => this.api.then(client => client.apis.agency.getListingTypesFromAgency({ agencyId }))))
    this.listingTypes = Array.from(new Set(Array.prototype.concat.apply([], listingTypes.map(lt => lt.body)))).sort()
  })

  @action getMainAgencyBackdrop = flow(function * () {
    const backdrop = yield fetch(`https://www.heliosconnect.fr/clients/${this.mainAgencyId}/logos/backdrop.jpg`)
    if (backdrop.ok) {
      this.mainAgencyBackdrop = URL.createObjectURL(yield backdrop.blob())
    } else {
      this.mainAgencyBackdrop = null
    }
  })

  @action getMainAgencyLogo = flow(function * () {
    const logo = yield fetch(`https://www.heliosconnect.fr/clients/${this.mainAgencyId}/logos/logo.png`)
    if (logo.ok) {
      this.mainAgencyLogo = URL.createObjectURL(yield logo.blob())
    } else {
      this.mainAgencyLogo = null
    }
  })

  @action getQuote = flow(function * (quoteRequest) {
    this.quotes[quoteRequest.listingId].state = 'pending'
    try {

      const quoteResponse = yield this.api.then(client => client.apis.quote.getQuote({}, { requestBody: { ...quoteRequest } }))

      let roundedInitialPrice = quoteResponse.body.pricingDetails.listingInitialPrice

      switch (this.agencies[quoteRequest.agencyId].roundingRule ) {
        case 1:
          roundedInitialPrice = Math.ceil(roundedInitialPrice * 10) / 10
          break
        case 2:
          roundedInitialPrice = Math.ceil(roundedInitialPrice)
          break
        case 3:
          roundedInitialPrice = Math.ceil(roundedInitialPrice / 10) * 10
          break
        default:
          break
      }


      const totalPrice = roundedInitialPrice +
        quoteResponse.body.pricingDetails.cleaningFee +
        quoteResponse.body.pricingDetails.processingFee +
        quoteResponse.body.pricingDetails.mandatoryOptionsCost +
        quoteResponse.body.pricingDetails.listingDiscount


      this.quotes[quoteRequest.listingId] = {
        ...quoteResponse.body,
        totalPrice,
        state: quoteResponse.body.error ? 'error' : 'done'
      }
      if (!quoteResponse.body.error) {
        if (quoteResponse.body.totalPrice < this.priceRangeDomain[0]) {
          this.priceRangeDomain[0] = Math.floor(quoteResponse.body.totalPrice)
          this.listingFilters.priceRange[0] = this.priceRangeDomain[0]
        }

        if (quoteResponse.body.totalPrice > this.priceRangeDomain[1]) {
          this.priceRangeDomain[1] = Math.ceil(quoteResponse.body.totalPrice)
          this.listingFilters.priceRange[1] = this.priceRangeDomain[1]
        }
      }
    } catch (err) {
      this.quotes[quoteRequest.listingId].state = 'error'
    }
  })

  @action handleTravelDatesChange = ({ startDate, endDate }) => {
    this.travelDates = {
      startDate,
      endDate
    }

    for (const listingId of Object.keys(this.quotes)) {
      this.quotes[listingId].state = 'new'
    }
  }

  @action initializePayment = flow(function * (paymentInitializationRequest) {
    const paymentInitializationResponse = yield this.api.then(client => client.http({
      body: JSON.stringify(paymentInitializationRequest),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      url: `${client.spec.schemes[0]}://${client.spec.host}/private/payment/initialize`
    }))

    this.payment = {
      response: paymentInitializationResponse.body,
      state: paymentInitializationResponse.ok ? 'done' : 'error'
    }
  })

  @action addOption = flow(function * (bookingId, listingOption, quantity) {
    const bookingOptionResponse = yield this.api.then(client => client.apis.bookings.addBookingOption({}, {
      requestBody: {
        BookingId: bookingId,
        ArticleId: listingOption.articleID,
        Name: listingOption.name,
        UnitPrice: listingOption.unitPrice,
        Deposit: listingOption.deposit,
        Quantity: quantity,
        Mandatory: false
      }
    }))
    this.addedOptions.push({
      optionId: bookingOptionResponse.body.optionNumber,
      state: bookingOptionResponse.body.error ? 'error' : 'done'
    })
  })

  @action resetTravelDates = () => {
    this.travelDates = {}
    for (const listingId of Object.keys(this.listings.response)) {
      this.quotes[listingId] = {
        state: 'new'
      }
      this.options[listingId] = []
    }
  }

  setBooleanFilter = filterName => action(`set${filterName.charAt(0).toUpperCase() + filterName.slice(1)}`, evt => {
    this.listingFilters[filterName] = evt.target.checked
  })

  @action setDestination = destination => {
    this.listingFilters.destination = destination
  }

  @action setListingType = listingType => {
    this.listingFilters.listingType = listingType
  }

  @action setPriceRange = values => {
    this.listingFilters.priceRange = values
  }

  @action setSurfaceAreaRange = values => {
    this.listingFilters.surfaceAreaRange = values
  }

  @action setTenantDetails = tenantDetails => {
    this.tenantDetails = tenantDetails
  }

  @action updateGuestCount = (counter, step) => {
    this.guestCount[counter] += step
  }
}
